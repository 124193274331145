import BaseSkeleton from '@/components/base/baseSkeleton';
import { getTopThreeKLinesInForeignExchangeApi } from '@/services/api/requestApi';
import { useReq } from '@/services/net/request';
import RenderUtil from '@/utils/RenderUtil';
import { useModel } from '@@/exports';
import { useMount } from 'ahooks';
import HomeTrend from './HomeTrend';
import style from './index.less';

export default () => {
  // 红涨绿跌设置
  const { upDownColorConfig } = useModel('system');
  const { getSocketRowByName } = useModel('socket');
  //涨
  const RiseColor = upDownColorConfig === 'upGreen' ? '#2db880' : '#ee4456';
  //跌
  const fallColor = upDownColorConfig === 'upGreen' ? '#ee4456' : '#2db880';
  const {
    data: topThreeKLinesInForeignExchange = [],
    run: getTopThreeKLinesInForeignExchangeReq,
    loading,
  } = useReq(getTopThreeKLinesInForeignExchangeApi, {
    manual: true,
    defaultValue: [],
  }) as any;
  useMount(() => {
    getTopThreeKLinesInForeignExchangeReq();
  });


  return (
    <div className='w-full px-4'>
      {Array.isArray(topThreeKLinesInForeignExchange) &&
        topThreeKLinesInForeignExchange.length > 0 && (
          <div
            className={`flex justify-between items-center pb-2 ${style.card} mx-[-8px]`}
          >
            {topThreeKLinesInForeignExchange
              ?.slice(0, 3)
              .map((item: any, index: number) => {
                const soketData = getSocketRowByName(item.symbol);

                return (
                  <div
                    className={`p-3 border-1 border-borderColor rounded-2xl w-full  ${
                      index === topThreeKLinesInForeignExchange?.length
                        ? ''
                        : 'mx-[6px]'
                    }`}
                    key={item.symbol}
                  >
                    <div className="text-[12px] truncate">{item.alias}</div>
                    <div className="font-bold mt-1 text-base">
                      {RenderUtil.FormatAmount(soketData.a, item?.priceAccuracy)}
                    </div>
                    <div className="flex items-center mt-1 pb-1">
                      {soketData.pe > 0 && (
                        <span
                          style={{
                            color: RiseColor,
                          }}
                          className=""
                        >
                          +
                        </span>
                      )}

                      <div
                        className="text-xs"
                        style={{
                          color: soketData.pe >= 0 ? RiseColor : fallColor,
                        }}
                      >
                        {RenderUtil.FormatAmount(soketData.pe, 2)}%
                      </div>
                    </div>

                    <HomeTrend
                      LineColor={'transparent'}
                      isUp={item.pe > 0}
                      data={item?.currencyCandlestickApiDTO?.map?.(
                        (i: any) => i?.c,
                      )}
                      canvasWidth={'calc(100% )'}
                      canvasHeight={'30px'}
                    />
                  </div>
                );
              })}
          </div>
        )}
      {
        <BaseSkeleton isLoaded={topThreeKLinesInForeignExchange.length === 0}>
          <div className={`flex overflow-x-auto pb-2 gap-3 ${style.card}`}>
            <div className="h-[120px] flex-1 flex-shrink-0 bg-backgroundAuxiliaryColor rounded-md"></div>
            <div className="h-[120px] flex-1 flex-shrink-0 bg-backgroundAuxiliaryColor rounded-md"></div>
            <div className="h-[120px] flex-1 flex-shrink-0 bg-backgroundAuxiliaryColor rounded-md"></div>
          </div>
        </BaseSkeleton>
      }
    </div>
  );
};
