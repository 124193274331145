import { useGlobalPrompt } from '@/components/base/basePromptWindow';
import ValueDisplay from '@/components/display/displayValueDisplay';
import RenderUtil from '@/utils/RenderUtil';
import { useModel } from '@umijs/max';
import { useCountDown } from 'ahooks';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
// 工具函数：将毫秒转换为 hh:mm:ss 格式
const formatCountdown = (ms: number): string => {
  const hours = String(Math.floor(ms / (1000 * 60 * 60))).padStart(2, '0'); // 小时
  const minutes = String(
    Math.floor((ms % (1000 * 60 * 60)) / (1000 * 60)),
  ).padStart(2, '0'); // 分钟
  const seconds = String(Math.floor((ms % (1000 * 60)) / 1000)).padStart(
    2,
    '0',
  ); // 秒
  return `${hours}:${minutes}:${seconds}`;
};
/**
 * 买卖价格波动组件 - PC
 * @returns
 */
export default function TransactionRightBox({ num }: any) {
  const { LocalChartInfo, coinType }: any = useModel('kline');
  const { getSocketDepthByName, getSocketRowByName, sendMsg, isSub }: any =
    useModel('socket');
  const socketData = getSocketRowByName(LocalChartInfo?.symbol);
  const depth = getSocketDepthByName(LocalChartInfo?.symbol);

  const getMinMax = (arr: any[], key: number) => {
    const values = arr.map((item) => item?.[key] || 0);
    return {
      max: Math.max(...values),
      min: Math.min(...values),
    };
  };

  const { t } = useTranslation();

  // 将买单按价格倒序排列（从高到低）
  const buyArr = [...(depth?.a || [])].sort((a, b) => b.p - a.p);

  // 将卖单按价格增序排列（从低到高）
  const sellArr = [...(depth?.b || [])].sort((a, b) => b.p - a.p);
  // 首先对 buyArr 和 sellArr 进行排序

  // 从排序后的数组中提取 item?.[1] 的值，并进行排序

  // 重新组装 buyArr 和 sellArr，保持 item?.[0] 的排序，而使用排序后的 item?.[1]
  const reassembledBuyArr = buyArr.map((item: any, index) => [item.p, item.n]);
  const reassembledSellArr = sellArr.map((item, index) => [item.p, item.n]);

  const { max: maxAsks, min: minAsks } = getMinMax(reassembledBuyArr, 0);
  const { max: maxBids, min: minBids } = getMinMax(reassembledSellArr, 0);

  useEffect(() => {
    if (LocalChartInfo && isSub) {
      sendMsg(
        `{"type":"SUB_DEPTH","data": ${JSON.stringify(
          LocalChartInfo?.symbol,
        )}}`,
      );
    }

    return () => {
      sendMsg(
        `{"type":"UNSUB_DEPTH","data": ${JSON.stringify(
          LocalChartInfo?.symbol,
        )}}`,
      );
    };
  }, [LocalChartInfo?.symbol, isSub]);

  const transactionAccuracy = LocalChartInfo?.transactionAccuracy;
  const { fundingRate } = useModel('socket');

  // 使用 targetDate 来管理倒计时
  const [targetDate, setTargetDate] = useState<number | undefined>();

  // 使用 useCountDown
  const [countdown] = useCountDown({
    targetDate,
    onEnd: () => {
      console.log('倒计时结束');
    },
  });

  // 监听 fundingRate 的变化并更新 targetDate
  useEffect(() => {
    if (fundingRate?.nextPeriodTimestamp) {
      setTargetDate(fundingRate.nextPeriodTimestamp); // 将目标时间设置为服务器返回的时间戳
    }
  }, [fundingRate]);

  const { openPrompt } = useGlobalPrompt();

  return (
    <div className="h-full flex flex-col min-h-[820px]">
      <div className="border-b-1 border-borderColor mb-2 pb-2 font-bold flex justify-between">
        {t('盘口')}

        <div className="flex flex-1 flex-col items-end justify-end text-[12px]">
          <div
            onClick={() => {
              openPrompt({
                showCancel: false,
                title: t('资金费率'),
                content: (
                  <strong>
                    Explanation: Funding fees are incurred every 8 hours between
                    long position holders and short position holders. If the
                    settlement funding rate is positive, long position holders
                    will pay fees to short position holders. Conversely, if the
                    funding rate is negative, short position holders will pay
                    fees to long position holders.
                    <br />
                    <br />
                    Note: The funding rate fluctuates in real-time based on
                    market conditions every 8 hours. During settlement, the
                    position value will be used to calculate the funding fee.
                    <br />
                    <br />
                    Funding Fee Formula: Funding Fee = Position Value × Funding
                    Rate
                  </strong>
                ),
              });
            }}
            className=" text-auxiliaryTextColor text-[11px] border-b-1 border-dashed"
          >
            {t('资金费率')} / {t('倒计时')}
          </div>
          <div className=" text-titleColor pt-[2px]">
            {fundingRate?.fundingRate ?? 0}% / {formatCountdown(countdown)}
          </div>
        </div>
      </div>

      <div className="flex justify-between text-xs pb-2 text-auxiliaryTextColor">
        <div className="flex-1">
          <div className="">{t('方向')}</div>
        </div>

        <div className=" flex-1">
          <div className="text-center">{t('价格')}</div>
        </div>

        <div className=" flex-1">
          <div className="text-right">{t('数量')}</div>
        </div>
      </div>

      <div className="flex flex-1 flex-col">
        <div>
          {reassembledSellArr?.map((item, index) => {
            const gradientLength =
              ((item?.[0] - minBids) / (maxBids - minBids)) * 100;

            return (
              <div
                key={index}
                style={{
                  position: 'relative',
                  overflow: 'hidden',
                }}
                className="pt-[3px] pb-[3px]"
                onClick={() => {}}
              >
                <div
                  style={{
                    position: 'absolute',
                    right: 0,
                    top: 0,
                    bottom: 0,
                    width: gradientLength,
                    background: 'rgba(255,0,0,0.05)',
                  }}
                />
                <div className="flex justify-between mb-[5px]">
                  <div className="text-xs w-[50px]  text-errorColor flex-1">
                    {t('卖')} {index + 1}
                  </div>

                  <div className="mb0  flex-1">
                    <ValueDisplay
                      propsColor="#f23645"
                      className="text-xxs my-[0]"
                      value={item?.[0]}
                      len={LocalChartInfo?.priceAccuracy}
                      isSubLen={false}
                    />
                  </div>

                  <div className="text-xxs text-center flex justify-end items-center  flex-1">
                    {RenderUtil.shortenNumber(
                      RenderUtil.formatNum(
                        item?.[1],
                        transactionAccuracy ?? item?.[1] < 1 ? 5 : 2,
                      ),
                    )}
                  </div>
                </div>
              </div>
            );
          })}
        </div>

        <div className="text-xs py-3 pb-5">
          <div className="flex">
            <div className="text-base">
              {/* 当前价 */}
              <ValueDisplay
                className="text-base font-bold"
                value={socketData.a}
                justify="justify-start"
                isSubLen={false}
                len={LocalChartInfo?.priceAccuracy ?? 2}
              />
            </div>
          </div>
        </div>

        <div>
          {reassembledBuyArr?.map((item, index) => {
            // 计算当前项的百分比宽度
            const gradientLength =
              ((item?.[0] - minAsks) / (maxAsks - minAsks)) * 100;

            return (
              <div
                key={index}
                style={{
                  position: 'relative',
                  overflow: 'hidden',
                }}
                className="pt-[3px] pb-[3px] "
                onClick={() => {}}
              >
                <div
                  style={{
                    position: 'absolute',
                    right: 0,
                    top: 0,
                    bottom: 0,
                    width: gradientLength,
                    background: 'rgba(144,238,144,0.05)',
                  }}
                />
                <div className="flex justify-between mb-[5px]">
                  <div className="text-xs  w-[50px] text-successColor  flex-1">
                    {t('买')} {reassembledBuyArr?.length - index}
                  </div>

                  <div className="mb0  flex-1">
                    <div>
                      <ValueDisplay
                        propsColor="#3a8e7f"
                        className="text-xxs"
                        value={item?.[0]}
                        len={LocalChartInfo?.priceAccuracy}
                        isSubLen={false}
                      />
                    </div>
                  </div>

                  <div className="mb0 flex justify-end items-center  flex-1">
                    <div className="text-xxs">
                      {RenderUtil.shortenNumber(
                        RenderUtil.formatNum(
                          item?.[1],
                          transactionAccuracy ?? item?.[1] < 1 ? 5 : 2,
                        ),
                      )}
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}
