import { AntDesignDownOutlined } from '@/assets/icons/comm/AntDesignDownOutlined';
import { LoadingSvg } from '@/assets/icons/comm/LoadingSvg';
import BaseInputNumber from '@/components/base/baseInputNumber';
import BaseSlideRange from '@/components/base/baseSlideRange';
import { OrderTypeEnum } from '@/pages/contract/components/ContractTypeModel';
import usePlaceAnOrderHooks from '@/pages/contract/usePlaceAnOrderHooks';
import RenderUtil from '@/utils/RenderUtil';
import {
  Accordion,
  AccordionItem,
  Button,
  Checkbox,
  ScrollShadow,
  Select,
  SelectItem,
} from '@nextui-org/react';
import { useModel } from '@umijs/max';
import { SetStateAction, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

/**
 * 交易 表单
 */
export default function PcContractLeft(props: any) {
  const { type } = props || {};
  const { t } = useTranslation();
  const { localContract ,} = useModel('contract');
  const { currentUpBgClass } = useModel('system');
  const {
    price,
    setPrice,
    
    priceAccuracy,
    soketData,
    lever,
    enableLever,
    setLever,
    currentPosition,
    buyType,
    num,
    maxInputVal,
    setNum,
    selectedKeys,
    setSelectedKeys,
    tp,
    setTp,
    tl,
    setTl,
    premium,
    handleSliderChange,
    submitLoading,
    onSubmit,
  } = usePlaceAnOrderHooks({
    type,
  });

  const { contractAvailableFunds } = useModel('user');
  // 选择限价时 更改币种 修改价格
  useEffect(()=>{
    setPrice(soketData?.a);
  },[localContract])
  return (
    <div className="flex-1">
      <div>
        <div className="flex justify-between space-x-6">
          <ScrollShadow hideScrollBar className="pt-[0px] flex-1">
            <div className="py-2 text-xs pt-2">
              <div className="flex justify-between pb-3 items-center">
                <div className=" text-auxiliaryTextColor w-[60px] font-bold">
                  {t('价格')}
                </div>
                {+type === 2 ? (
                  <BaseInputNumber
                    value={price}
                    step={1 / Math.pow(10, priceAccuracy)}
                    len={priceAccuracy}
                    onChange={(value: SetStateAction<number>) => {
                      setPrice(value);
                    }}
                    className="flex-1"
                  />
                ) : (
                  <div className=" font-bold  bg-backgroundAuxiliaryColor flex-1 rounded-md p-3 text-sm14">
                    {RenderUtil.FormatAmount(soketData?.a, priceAccuracy, true)}
                  </div>
                )}
              </div>
              <div className="pb-1 pt-1 flex justify-between items-center">
                <div className="flex justify-between items-center flex-1">
                  <div className=" text-auxiliaryTextColor w-[60px] font-bold">
                    {t('倍数')}
                  </div>

                  <div
                    className="pb-2 flex-1 w-full"
                    onClick={(event) => event.stopPropagation()}
                  >
                    <Select
                      fullWidth
                      className="w-full mt-2"
                      placeholder={t('请选择杠杆')}
                      selectedKeys={[lever]}
                      classNames={{
                        trigger: `bg-backgroundAuxiliaryColor rounded-md h-[30px]`,
                      }}
                      popoverProps={{
                        classNames: {
                          content:
                            'bg-backgroundAuxiliaryColor text-foreground',
                        },
                      }}
                      onClick={(event) => event.stopPropagation()}
                    >
                      {enableLever?.map((item) => (
                        <SelectItem
                          key={item}
                          value={item}
                          onClick={() => {
                            setLever(item);
                            if (!currentPosition) return;
                          }}
                        >
                          {item + 'x'}
                        </SelectItem>
                      ))}
                    </Select>
                  </div>
                </div>
              </div>

              {
                // <div className="flex justify-between pb-3 text-auxiliaryTextColor">
                //   <div className="font-bold">
                //     1 {t('手')}= {firstHand} {localContract?.baseSymbol}
                //   </div>
                // </div>
              }

              <div className="pb-0 pt-3">
                <div className="flex items-center pt-3">
                  <div className="pb-2 text-auxiliaryTextColor w-[60px] font-bold">
                    {buyType === OrderTypeEnum.QUANTITY_ORDER && t('数量')}
                    {buyType === OrderTypeEnum.ZHANG_ORDER && t('数量(张)')}
                    {buyType === OrderTypeEnum.VALUE_ORDER &&
                      t('金额') + ' (' + localContract?.coinAlias + ' )'}
                    {buyType === OrderTypeEnum.MARGIN_ORDER &&
                      t('金额') + ' (' + localContract?.coinAlias + ' )'}
                  </div>
                  <div className="bg-backgroundAuxiliaryColor rounded-md flex items-center flex-1">
                    <BaseInputNumber
                      defaultValue={''}
                      value={num}
                      step={1}
                      len={0}
                      max={maxInputVal as any}
                      placeholder={t('单张面值{{parValue}} {{symbol}}', {
                        parValue: localContract?.earnestMoney,
                        symbol: 'USDT',
                      })}
                      min={1}
                      onChange={(value: any, isAdd: any) => {
                        if (num === value) return;
                        if (value === '0.' && isAdd) {
                          setNum(1);
                        } else {
                          setNum(value);
                        }
                      }}
                      className="flex-1 !py-0 !my-[1px]"
                    />
                  </div>
                </div>

                <div className="px-2 overflow-visible py-3">
                  <BaseSlideRange
                    values={[1, 25, 50, 75, 100]}
                    step={1}
                    value={currentPosition}
                    isShowButtonNumber={true}
                    onChange={handleSliderChange}
                  />
                </div>

                <div className="flex items-center py-1">
                  <div className="text-auxiliaryTextColor">{t('最大可开')}</div>
                  <div className="font-bold ml-1">
                    {maxInputVal} {t('张')}
                  </div>
                </div>

                <div className="flex items-center py-1">
                  <div className="text-auxiliaryTextColor">{t('手续费')}</div>
                  <div className="font-bold ml-1">
                    {RenderUtil.FormatAmount(premium, 2)}
                    <span className="ml-1">{localContract?.coinAlias}</span>
                  </div>
                </div>

                <div className="flex items-center py-1">
                  <div className="text-auxiliaryTextColor">{t('保证金')}</div>
                  <div className="font-bold ml-1">
                    {[OrderTypeEnum.ZHANG_ORDER].includes(buyType) &&
                      RenderUtil.FormatAmount(
                        (num * localContract?.earnestMoney) / lever,
                        2,
                      ) + ' '}
                  </div>
                </div>

                <div className="flex items-center py-1">
                  <div className="text-auxiliaryTextColor">{t('可用资产')}</div>
                  <div className="font-bold ml-1">
                    {RenderUtil.FormatAmount(contractAvailableFunds, 2)}
                    {' ' + localContract?.coinAlias}
                  </div>
                </div>
              </div>

              <Accordion
                selectedKeys={selectedKeys}
                onSelectionChange={setSelectedKeys}
              >
                <AccordionItem
                  key="1"
                  title={
                    <span className="text-xss">
                      <Checkbox
                        isSelected={selectedKeys.size !== 0}
                        size="sm"
                        color="default"
                        onClick={() => {
                          setSelectedKeys(
                            selectedKeys.size === 0
                              ? new Set(['1'])
                              : new Set([]),
                          );
                        }}
                      >
                        <span className="text-xxs">{t('止盈/止损')}</span>
                      </Checkbox>
                    </span>
                  }
                  indicator={<AntDesignDownOutlined />}
                >
                  <div className="pb-2">
                    <div className="font-bold">{t('止盈')}</div>
                    <BaseInputNumber
                      value={tp}
                      defaultValue=""
                      step={1 / Math.pow(10, priceAccuracy ?? 0)}
                      min={1 / Math.pow(10, priceAccuracy)}
                      len={priceAccuracy}
                      // len={tp === 0 || tp === '' ? 0 : priceAccuracy}
                      onChange={(value: any, isAdd: any) => {
                        if (!tp && soketData?.a && isAdd) {
                          setTp(soketData?.a);
                        } else if (Number(value) === 0 && isAdd) {
                          setTp(1 / Math.pow(10, priceAccuracy));
                        } else {
                          setTp(value);
                        }
                      }}
                      className="my-1"
                    />
                    <div className="text-auxiliaryTextColor">
                      {t('当最新价格触达时，将会触发市价止盈')}。
                    </div>
                  </div>

                  <div className="pb-2">
                    <div className="font-bold">{t('止损')}</div>
                    <BaseInputNumber
                      value={tl}
                      defaultValue=""
                      step={1 / Math.pow(10, priceAccuracy ?? 0)}
                      className="my-1"
                      min={1 / Math.pow(10, priceAccuracy)}
                      len={priceAccuracy}
                      // len={tl === 0 || tl === '' ? 0 : priceAccuracy}
                      onChange={(value: any, isAdd: any) => {
                        if (!tl && soketData?.a && isAdd) {
                          setTl(soketData?.a);
                        } else if (Number(value) === 0 && isAdd) {
                          setTl(1 / Math.pow(10, priceAccuracy));
                        } else {
                          setTl(value);
                        }
                      }}
                    />

                    <div className="text-auxiliaryTextColor">
                      {t('当最新价格触达时，将会触发市价止损')}。
                    </div>
                  </div>
                </AccordionItem>
              </Accordion>
            </div>
            <div className="flex items-center gap-3">
              <Button
                spinner={<LoadingSvg />}
                isLoading={submitLoading}
                onClick={() => {
                  onSubmit(1);
                }}
                className={`${currentUpBgClass} w-full text-[#fff] rounded-md`}
              >
                <div className="text-xs flex justify-between items-center">
                  <img
                    src={require('@/assets/img/trade/long.png')}
                    className="w-[12px] h-[12px] mr-1"
                  />

                  <div>{t('买涨')}</div>
                </div>
              </Button>
            </div>
          </ScrollShadow>
        </div>
      </div>
    </div>
  );
}
