// @ts-nocheck
import { ComponentType } from 'react';
import ErrorBoundary from './ErrorBoundary';

// 定义一个类型，它包括所有WrappedComponent的属性加上你想额外支持的属性
type WithErrorBoundaryProps<T> = T & {
  [key: string]: any;
};

function BaseWithErrorBoundary<T>(
  WrappedComponent: ComponentType<T> | any,
): any {
  // ComponentType<WithErrorBoundaryProps<T>> 表明返回的组件类型正确地扩展了额外的属性
  return function ComponentWithBoundary(props: WithErrorBoundaryProps<T>) {
    return (
      <ErrorBoundary>
        <WrappedComponent {...(props as T)} />
      </ErrorBoundary>
    );
  };
}

export default BaseWithErrorBoundary;
