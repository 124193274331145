import { AITRADE } from '@/assets/icons/comm/AITRADE';
import { IeoSvg } from '@/assets/icons/comm/IeoSvg';
import { INVITE } from '@/assets/icons/comm/INVITE';
import { Loan } from '@/assets/icons/comm/Loan';
import { RegularAitrade } from '@/assets/icons/comm/RegularAitrade';
import { VIP } from '@/assets/icons/comm/VIP';
import {
  FINANCIAL_MANAGEMENT_LIST_ENUM,
  GetCoinListTypeEnum,
} from '@/enums/businessEnum';
import PageEnum from '@/enums/pageEnum';
import { Divider } from '@nextui-org/react';
import { useModel } from '@umijs/max';
import React, { memo, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import 'swiper/css';
import 'swiper/css/pagination'; // 导入分页器样式
import { Navigation, Pagination } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import { history } from 'umi';
import RechargeJumpModal from '@/pages/funds/index/components/RechargeJumpModal';

interface IProps {
  id?: any;
}

export default memo(({}: IProps) => {
  const { t } = useTranslation();
  const { language } = useModel('language');

  // 最多显示4个
  const arr = useMemo(() => {
    return [
      {
        text: t('邀请返佣'),
        Icon: INVITE,
        path: PageEnum.INVITE,
      },
      {
        text: t('理财'),
        Icon: RegularAitrade,
        path:
          PageEnum.AITRADE +
          '?type=' +
          FINANCIAL_MANAGEMENT_LIST_ENUM.REGULAR_FINANCIAL_MANAGEMENT,
      },
      {
        text: t('贷款'),
        Icon: Loan,
        path: PageEnum.LOANS,
      },
      {
        text: t('VIP权益'),
        Icon: VIP,
        path: PageEnum.APPLY_VIP,
      },
      {
        text: t('IEO申购'),
        Icon: IeoSvg,
        path: PageEnum.MARKET + '?type=' + GetCoinListTypeEnum.IEO,
      },
      {
        text:
          language === 'it'
            ? "Gestione finanziaria dell'intelligenza artificiale"
            : t('AI交易'),
        Icon: AITRADE,
        path:
          PageEnum.AITRADE +
          '?type=' +
          FINANCIAL_MANAGEMENT_LIST_ENUM.QUANTIFICATION_FINANCIAL_MANAGEMENT,
      },
    ];
  }, []);

  // 主体布局
  return (
    <div className="w-full px-4 bg-[transparent] mt-3">
      <div className="flex justify-between pt-[14px] flex-wrap ">
        {/*<Swiper*/}
        {/*  key={'Swiper'}*/}
        {/*  // 配置Swiper使用的模块*/}
        {/*  modules={[Pagination, Navigation]}*/}
        {/*  slidesPerView={4} // 一排显示 四个 项目*/}
        {/*  pagination={{ clickable: true }} // 启用可点击的分页器*/}
        {/*  className={`h-[80px] `}*/}
        {/*  autoHeight={false} // 禁用自动高度*/}
        {/*>*/}
        {/*  {arr.map((item: any, index: number) => {*/}
        {/*    return (*/}
        {/*      <SwiperSlide*/}
        {/*        key={item.text}*/}
        {/*        className={`bg-cover bg-center w-1/4`}*/}
        {/*        onClick={() => {*/}
        {/*          history.push(item.path, item?.state);*/}
        {/*        }}*/}
        {/*      >*/}
        {/*        <div className="text-center">*/}
        {/*          <div className="m-auto text-center flex justify-center">*/}
        {/*            <item.Icon className="text-iconColor" />*/}
        {/*          </div>*/}
        {/*          <div className="pt-2 text-xs">{item.text}</div>*/}
        {/*        </div>*/}
        {/*      </SwiperSlide>*/}
        {/*    );*/}
        {/*  })}*/}
        {/*</Swiper>*/}
        {arr?.map((item: any) => {
          return (
            <>
              {item.path !== PageEnum.TopUp && (
                <div
                  key={item.text}
                  className="text-center w-1/3 mb-5"
                  onClick={() => {
                    history.push(item.path, item?.state);
                  }}
                >
                  <div className=" flex-1 m-auto text-center flex justify-center">
                    <item.Icon className="text-iconColor" />
                  </div>
                  <div className="pt-2 text-xs">{item.text}</div>
                </div>
              )}
              {item.path === PageEnum.TopUp && (
                <div key={item.text} className="flex-1 text-center  w-1/3">
                  <RechargeJumpModal>
                    <div className=" flex-1 m-auto text-center flex justify-center ">
                      <item.Icon className="text-iconColor" />
                    </div>
                    <div className="pt-2 text-xs">{item.text}</div>
                  </RechargeJumpModal>
                </div>
              )}
            </>
          );
        })}
      </div>

      {/* <Divider className="my-3 pb-0 !bg-backgroundAuxiliaryColor" /> */}
    </div>
  );
});
