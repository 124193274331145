import type { SVGProps } from 'react';

export function IeoSvg(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="19"
      height="18"
      viewBox="0 0 19 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_204_1007)">
        <path
          d="M13.75 8C12.4239 8 11.1521 8.52678 10.2145 9.46447C9.27678 10.4021 8.75 11.6739 8.75 13C8.75 14.3261 9.27678 15.5979 10.2145 16.5355C11.1521 17.4732 12.4239 18 13.75 18C15.0761 18 16.3479 17.4732 17.2855 16.5355C18.2232 15.5979 18.75 14.3261 18.75 13C18.75 11.6739 18.2232 10.4021 17.2855 9.46447C16.3479 8.52678 15.0761 8 13.75 8ZM16.8421 12.7884L15.823 13.7477C15.7846 13.7837 15.7554 13.8285 15.7381 13.8782C15.7207 13.9279 15.7157 13.9811 15.7234 14.0332L15.9359 15.4158C15.9484 15.501 15.9369 15.5881 15.9028 15.6672C15.8686 15.7463 15.8132 15.8144 15.7426 15.8638C15.672 15.9133 15.589 15.9421 15.503 15.9471C15.417 15.9521 15.3312 15.933 15.2554 15.8921L14.0272 15.2199C13.9816 15.1946 13.9305 15.1809 13.8784 15.18C13.8263 15.1792 13.7748 15.1911 13.7284 15.2149L12.477 15.8456C12.3995 15.8851 12.3125 15.9019 12.2259 15.8944C12.1394 15.8869 12.0566 15.8552 11.9871 15.803C11.9176 15.7508 11.8642 15.6801 11.8328 15.5991C11.8015 15.518 11.7935 15.4298 11.8098 15.3444L12.0703 13.9701C12.0802 13.9187 12.0774 13.8657 12.0621 13.8156C12.0467 13.7656 12.0194 13.72 11.9824 13.683L10.9965 12.6888C10.9353 12.6271 10.8927 12.5496 10.8734 12.465C10.854 12.3803 10.8588 12.2919 10.8872 12.2099C10.9155 12.1278 10.9663 12.0553 11.0337 12.0006C11.1011 11.9459 11.1825 11.9112 11.2687 11.9004L12.6562 11.7245C12.7082 11.718 12.7577 11.6988 12.8005 11.6687C12.8433 11.6386 12.8781 11.5985 12.9019 11.5519L13.5425 10.3071C13.5823 10.23 13.6428 10.1657 13.7173 10.1213C13.7917 10.0768 13.8771 10.0541 13.9638 10.0557C14.0504 10.0572 14.135 10.083 14.2077 10.13C14.2805 10.1771 14.3387 10.2436 14.3757 10.322L14.9732 11.5867C14.9957 11.634 15.0291 11.6752 15.0706 11.707C15.1122 11.7387 15.1607 11.7601 15.2122 11.7693L16.5932 11.995C16.6791 12.0075 16.7598 12.0441 16.8259 12.1004C16.892 12.1567 16.9409 12.2306 16.9669 12.3135C16.9929 12.3964 16.995 12.4849 16.9729 12.5689C16.9509 12.6529 16.9055 12.729 16.8421 12.7884Z"
          fill="hsl(var(--nextui-primary)"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0.75 1C0.75 0.447715 1.19772 0 1.75 0H15.75C16.3023 0 16.75 0.447715 16.75 1V7.02275C15.7129 6.36006 14.5 6 13.25 6C11.5261 6 9.87279 6.68482 8.65381 7.90381C7.43482 9.12279 6.75 10.7761 6.75 12.5C6.75 14.179 7.39959 15.791 8.55958 17H1.75C1.19772 17 0.75 16.5523 0.75 16V1ZM3.17334 5H7.97334V6.28571H3.17334V5ZM7.97334 2H3.17334V3.28571H7.97334V2ZM11.75 2H14.3214V3.28571H11.75V2Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_204_1007">
          <rect
            width="18"
            height="18"
            fill="transparent"
            transform="translate(0.75)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
