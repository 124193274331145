import type { SVGProps } from 'react';

export function VIP(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M16.9992 5.9994V13.9986C16.9992 14.5512 16.551 14.9994 15.9984 14.9994H1.99982C1.44722 14.9994 0.999023 14.5512 0.999023 13.9986V5.9994H16.9992ZM4.31642 7.7166L5.84102 12.0006H6.68702L8.21162 7.7166H7.45022L6.27482 11.2086H6.25682L5.08142 7.7166H4.31642ZM8.66162 7.7166V12.0006H9.36362V7.7166H8.66162ZM10.2024 7.7166V12.0006H10.9044V10.3572H11.9664C13.0104 10.3572 13.5324 9.9126 13.5324 9.0306C13.5324 8.154 13.0104 7.7166 11.979 7.7166H10.2024ZM10.9044 8.316H11.925C12.231 8.316 12.4596 8.37 12.6036 8.4834C12.7476 8.5914 12.825 8.7714 12.825 9.0288C12.825 9.2862 12.753 9.4734 12.609 9.5868C12.465 9.6948 12.2364 9.7542 11.925 9.7542H10.9044V8.316Z"
        fill="currentColor"
      />
      <path
        d="M1.00098 5.00039H16.9994L17.003 3.99959C17.003 3.44699 16.5548 2.99879 16.0022 2.99879H2.00178C1.44918 2.99879 1.00098 3.44699 1.00098 3.99959V5.00039Z"
        fill="hsl(var(--nextui-primary)"
      />
    </svg>
  );
}
