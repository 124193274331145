import BasePullDownToRefresh from '@/components/base/basePullDownToRefresh';
import BaseWithErrorBoundary from '@/components/base/baseWithErrorBoundary';
import CacheEnum from '@/enums/cacheEnum';
import { useLocalStorage } from '@/hooks/useStorage';
import { getUnreadNotice } from '@/services/api/requestApi';
import { useReq } from '@/services/net/request';
import {
  useDebounce,
  useDebounceFn,
  useDocumentVisibility,
  useMount,
} from 'ahooks';
import { useEffect, useState } from 'react';
import { useModel } from 'umi';
import HomePcView from '../pcPages/HomePcView';
import HomBanner from './components/HomBanner';
import ButtonGroup from './components/HomeButtonGroup';
import Market from './components/HomeMarket';
import { HomeNotice } from './components/HomeNotice';
import HomeQuotesCards from './components/HomeQuotesCards';
import TopCard from './components/HomeTopCard';
import { toast } from '@/layouts/components/ToastMessage';
import AnnouncementPopUpModal from '@/layouts/components/AnnouncementPopUpModal';

//首页
export default BaseWithErrorBoundary(() => {
  // 基础定义
  const { isPc } = useModel('system');
  const { requestUserInfo, fundingOverviewSocketMapReq } =
    useModel('user') || {};
  const [isOpen, setIsOpen] = useState(false);
  const [type] = useLocalStorage({
    key: CacheEnum.APP_LOGIN_TYPE,
  });
  // 获取未读公告
  const { runAsync: getNoticeUnread, data: noticeData = [] as any } = useReq(
    () => getUnreadNotice(),
    {
      formatResult: (res: any) => {
        return res?.data ?? [];
      },
    },
  );

  useMount(() => {
    // 获取用户信息
    requestUserInfo();
    if (type && type === '1') {
      getNoticeUnread();
    }
    // 解决弹框 页面跳转问题
    const handleRouteChange = () => {
      setIsOpen(false);
    };

    window.addEventListener('popstate', handleRouteChange);
    return () => {
      window.removeEventListener('popstate', handleRouteChange);
    };
  });

  // 回到页面刷新
  const [homeKey, setHomeKey] = useState(1);
  const documentVisibility: any = useDocumentVisibility();
  //监听用户回app的事件
  const { run: reload } = useDebounceFn(
    () => {
      setHomeKey((prevState) => prevState + 1);
    },
    {
      wait: 200,
    },
  );
  const { language } = useModel('language');

  useEffect(() => {
    if (documentVisibility === 'visible' && navigator.onLine) {
      reload();
    }
  }, [documentVisibility]);

  // 
  const debouncedHomeKey = useDebounce(homeKey, { wait: 999 });

  if (isPc) {
    return <HomePcView />;
  }
  return (
    <>
      <BasePullDownToRefresh
        refreshFn={async () => {
          setHomeKey((prevState) => prevState + 1);
          requestUserInfo?.(); // 增加 一个用户刷新，避免实名以后
          fundingOverviewSocketMapReq();
          return true;
        }}
      >
        <div className="pt-2 flex flex-1">
          <div className="w-full">
            {/* 顶部 */}
            <TopCard key={debouncedHomeKey} />

            {/* 轮播 */}
            <HomBanner />

            {/* 按钮组合 */}
            <ButtonGroup />

            {/*三个卡片组件*/}
            <HomeQuotesCards />

            {/* 行情组件 */}
            <Market />

            {/* 弹出消息通知的组件 */}
            <HomeNotice
              data={noticeData}
              isOpen={isOpen}
              onCloseModal={() => {
                setIsOpen(false);
              }}
              onClose={() => {
                setIsOpen(false);
              }}
            />
            {/* 底部 */}
            {/*<div>{<BottomCard />}</div>*/}
          </div>
        </div>
      </BasePullDownToRefresh>
    </>
  );
});
