import BaseWithErrorBoundary from '@/components/base/baseWithErrorBoundary';
import FeaturesExchangeSelect from '@/components/features/featuresExchangeSelect';
import PageEnum from '@/enums/pageEnum';

import { Button } from '@nextui-org/react';
import { history, useModel } from '@umijs/max';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useMemoShowFunds } from '@/hooks/useMemoShowFunds';

interface IProps {
  id?: any;
}

/**
 * 首页顶部
 */
export default BaseWithErrorBoundary(({}: IProps) => {
  // 基础定义
  const { t } = useTranslation();

  const { currentExchangeFunds, exchangeConverFunds,exchangeSetConvert } = useModel('exchange');

  const { isRealname, renderFunds, fundingOverviewSocketMapApiData, contractProfitAndLoss } =
    useModel('user');

  // 总资金
  const totalFunds = useMemoShowFunds(() => {
    return renderFunds?.overview?.total + contractProfitAndLoss;
  }, [renderFunds?.overview?.total, contractProfitAndLoss]);

  // 主体布局
  return (
    <div>
      <div className="px-4  flex justify-between  items-center">
        <div className="w-full">
          <div className="text-sm flex items-center">
            {t('总资产')}

            {/* 汇率下拉 */}
            <FeaturesExchangeSelect />
          </div>
          <div className="pt-2 flex items-center justify-between">
            {/* 余额 全部 */}
            <div className="mb-1">
              <span
                            className="text-2xl font-bold flex-1 max-w-[250px]"
                            style={{ overflowWrap: 'break-word' }}
                          >
              {exchangeConverFunds({
                price: totalFunds,
                currency: currentExchangeFunds?.exchange,
                isJoin: false,
                defaultVal: '--', // loading 的时候显示的
              })}
            </span>
              <div className="text-auxiliaryTextColor">
                ≈{' '}
                {exchangeSetConvert({
                  price: totalFunds,
                  isJoin: true,
                })}
              </div>
            </div>
            <div>
              {!isRealname && (
                <Button
                  onClick={() => {
                    history.push(PageEnum.VERIFIED);
                  }}
                  className="mainColorButton  !py-[15px]"
                  style={{ height: '32px' }}
                >
                  {t('去认证')}
                </Button>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});
