import { MaterialSymbolsAssignmentRounded } from '@/assets/icons/funds/MaterialSymbolsAssignmentRounded';
import { GetCoinListTypeEnum, WalletTypeEnum } from '@/enums/businessEnum';
import PageEnum from '@/enums/pageEnum';
import ContractOrderList from '@/pages/order/index/components/ContractOrderList';
import { history } from '@@/core/history';
import useUrlState from '@ahooksjs/use-url-state';
import { useModel } from '@umijs/max';
import { useTranslation } from 'react-i18next';
import CoinOrderList from './CoinOrderList';

export default () => {
  const [urlState] = useUrlState<any>({});
  // 标记是合约交易还是BB交易然后展示不同的订单
  const { type = GetCoinListTypeEnum.CONTRACT } = urlState || {};
  const { t } = useTranslation();

  const { localContract } = useModel('contract');

  const { localFastContract } = useModel('contract');

  const { localCoin } = useModel('coin');

  return (
    <>
      {/*Fast合约订单*/}
      {type === GetCoinListTypeEnum.FASTCONTRACT && (
        <CoinOrderList symbol={localFastContract?.id} />
      )}
      {/*合约订单*/}
      {type === GetCoinListTypeEnum.CONTRACT && (
        <ContractOrderList
          symbol={localContract?.id}
          siftNode={
            // 筛选按钮替换为进入订单页面
            <MaterialSymbolsAssignmentRounded
              onClick={() => {
                history.push(
                  PageEnum.ORDER + '?type=' + WalletTypeEnum.CONTRACT,
                );
              }}
              width="1.3em"
              height="1.3em"
              className="cursor-pointer text-foreground  mr-2"
            />
          }
        />
      )}
      {/*币币订单*/}
      {type === GetCoinListTypeEnum.COIN && (
        <CoinOrderList
          symbol={localCoin.baseId}
          siftNode={
            <MaterialSymbolsAssignmentRounded
              onClick={() => {
                history.push(PageEnum.ORDER + '?type=' + WalletTypeEnum.COIN);
              }}
              width="1.3em"
              height="1.3em"
              className="cursor-pointer text-foreground pt-1 mr-2"
            />
          }
        />
      )}
    </>
  );
};
