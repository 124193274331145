import ValueDisplay from '@/components/display/displayValueDisplay';
import RenderUtil from '@/utils/RenderUtil';
import { useModel } from '@umijs/max';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

/**
 * 买卖价格波动组件 - PC
 * 币币
 * @returns
 */
export default function PcCoinRightBox({ num }: any) {
  const { LocalChartInfo }: any = useModel('kline');
  const { getSocketRowByName, sendMsg, isSub, coinDepthList }: any =
    useModel('socket');

  const socketData = getSocketRowByName(LocalChartInfo?.symbol);

  const getSocketCoinDepthByName = (name: string): any => {
    if (coinDepthList.length) {
      const cur = coinDepthList?.find((i: any) => i.p === name);
      if (cur) return cur;
    }
    return {
      a: [],
      b: [],
      p: '',
    };
  };

  const depth = getSocketCoinDepthByName(LocalChartInfo?.symbol);

  const getMinMax = (arr: any[], key: number) => {
    const values = arr.map((item) => item?.[key] || 0);
    return {
      max: Math.max(...values),
      min: Math.min(...values),
    };
  };

  const { t } = useTranslation();
  // const buyArr = [...(depth?.a || []),];
  // const sellArr = [...(depth?.b || []), ];

  // 首先对 buyArr 和 sellArr 进行排序
  const buyArr = [...(depth?.a || [])].sort((a, b) => b.p - a.p);

  // 将卖单按价格增序排列（从低到高）
  const sellArr = [...(depth?.b || [])].sort((a, b) => b.p - a.p);

  // 从排序后的数组中提取 item?.[1] 的值，并进行排序
  const sortedBuyPrices = buyArr.map((item) => item?.n).sort((a, b) => a - b);
  const sortedSellPrices = sellArr.map((item) => item?.n).sort((a, b) => b - a);

  // 重新组装 buyArr 和 sellArr，保持 item?.[0] 的排序，而使用排序后的 item?.[1]
  const reassembledBuyArr = buyArr.map((item: any, index) => [
    item.p,
    sortedBuyPrices[index],
  ]);
  const reassembledSellArr = sellArr.map((item, index) => [
    item.p,
    sortedSellPrices[index],
  ]);

  const { max: maxAsks, min: minAsks } = getMinMax(reassembledBuyArr, 0);
  const { max: maxBids, min: minBids } = getMinMax(reassembledSellArr, 0);

  useEffect(() => {
    if (LocalChartInfo?.symbol && isSub) {
      sendMsg(
        `{"type":"SUB_COIN_DEPTH","data": ${JSON.stringify(
          LocalChartInfo?.symbol,
        )}}`,
      );
    }
  }, [LocalChartInfo?.symbol, isSub]);

  const [mixedArr, setMixedArr] = useState<any[]>([]);

  useEffect(() => {
    const updateMixedArr = () => {
      // 混合买卖数组并打乱顺序
      const combinedArr = [
        ...buyArr.map((item) => ({ ...item, type: 'buy' })),
        ...sellArr.map((item) => ({ ...item, type: 'sell' })),
      ];
      // 从 buyArr 截取5个数据放入 combinedArr 中
      const extraData = buyArr
        .slice(0, 2)
        .map((item) => ({ ...item, type: 'extra' }));
      combinedArr.push(...extraData);

      setMixedArr(combinedArr.sort(() => Math.random() - 0.5));
    };
    updateMixedArr(); // 初始化调用一次
    const interval = setInterval(updateMixedArr, 450); // 每1秒更新一次
    return () => clearInterval(interval); // 清除定时器
  }, [depth]);

  const transactionAccuracy = LocalChartInfo?.transactionAccuracy;

  return (
    <div className="flex space-x-3">
      <div className="h-full flex flex-col flex-1">
        <div className="border-b-1 border-borderColor mb-2 pb-2 font-bold">
          {t('盘口')}
        </div>

        <div className="flex justify-between text-xs pb-2 text-auxiliaryTextColor">
          <div className=" flex-1">
            <div className="">{t('方向')}</div>
          </div>

          <div className=" flex-1">
            <div className="text-center">{t('价格')}</div>
          </div>

          <div className=" flex-1">
            <div className=" text-right">{t('数量')}</div>
          </div>
        </div>

        <div className="flex flex-1 flex-col h-full ">
          <div className="">
            {reassembledSellArr?.map((item, index) => {
              const gradientLength =
                ((item?.[0] - minBids) / (maxBids - minBids)) * 100;

              return (
                <div
                  key={index}
                  style={{
                    position: 'relative',
                    overflow: 'hidden',
                  }}
                  className="pt-[2px] pb-[2px]"
                  onClick={() => {}}
                >
                  <div
                    style={{
                      position: 'absolute',
                      right: 0,
                      top: 0,
                      bottom: 0,
                      width: gradientLength,
                      background: 'rgba(255,0,0,0.05)',
                    }}
                  />
                  <div className="flex justify-between mb-[5px]">
                    <div className="text-xs  w-[50px] text-errorColor flex-1">
                      {t('卖')} {index + 1}
                    </div>

                    <div className="mb0 flex-1">
                      <ValueDisplay
                        propsColor="#f23645"
                        className="text-xxs my-[0] !font-[500]"
                        value={item?.[0]}
                        len={LocalChartInfo?.priceAccuracy}
                        isSubLen={false}
                      />
                    </div>

                    <div className="text-xxs text-center flex justify-end items-center flex-1">
                      {RenderUtil.shortenNumber(
                        RenderUtil.formatNum(
                          item?.[1],
                          transactionAccuracy ?? item?.[1] < 1 ? 5 : 2,
                        ),
                      )}
                    </div>
                  </div>
                </div>
              );
            })}
          </div>

          <div className="text-xs pb-3 mt-2">
            <div className="flex">
              <div className="text-base">
                {/* 当前价 */}
                <ValueDisplay
                  className="text-base font-bold"
                  value={socketData.a}
                  justify="justify-start"
                  isSubLen={false}
                  len={LocalChartInfo?.priceAccuracy ?? 2}
                />
              </div>
            </div>
          </div>

          <div className="">
            {reassembledBuyArr?.map((item, index) => {
              // 计算当前项的百分比宽度
              const gradientLength =
                ((item?.[0] - minAsks) / (maxAsks - minAsks)) * 100;

              return (
                <div
                  key={index}
                  style={{
                    position: 'relative',
                    overflow: 'hidden',
                  }}
                  className="pt-[2px] pb-[2px] "
                  onClick={() => {}}
                >
                  <div
                    style={{
                      position: 'absolute',
                      right: 0,
                      top: 0,
                      bottom: 0,
                      width: gradientLength,
                      background: 'rgba(144,238,144,0.05)',
                    }}
                  />
                  <div className="flex justify-between mb-[5px]">
                    <div className="text-xs text-successColor w-[50px] flex-1">
                      {t('买')} {reassembledBuyArr?.length - index}
                    </div>

                    <div className="mb0  flex-1">
                      <div>
                        <ValueDisplay
                          propsColor="#3a8e7f"
                          className="text-xxs !font-[500]"
                          value={item?.[0]}
                          len={LocalChartInfo?.priceAccuracy}
                          isSubLen={false}
                        />
                      </div>
                    </div>

                    <div className="mb0 flex justify-end items-center flex-1">
                      <div className="text-xxs">
                        {RenderUtil.shortenNumber(
                          RenderUtil.formatNum(
                            item?.[1],
                            transactionAccuracy ?? item?.[1] < 1 ? 5 : 2,
                          ),
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>

      <div className="border-r-1 border-borderColor border-dashed w-[1px]"></div>

      <div className="flex space-x-3 flex-1">
        <div className="h-full flex flex-col flex-1">
          <div className="border-b-1 border-borderColor mb-2 pb-2 font-bold">
            {t('全站成交')}
          </div>

          <div className="flex justify-between text-xs pb-2 text-auxiliaryTextColor">
            <div className="flex-1">
              <div className="">{t('方向')}</div>
            </div>

            <div className="flex-1">
              <div className=" text-center">{t('价格')}</div>
            </div>

            <div className="flex-1">
              <div className=" text-right">{t('数量')}</div>
            </div>
          </div>

          <div className="flex flex-1 flex-col">
            <div>
              {/* 买卖数据 */}
              {mixedArr.map((item, index) => {
                // 计算当前项的百分比宽度
                const gradientLength =
                  item.type === 'buy'
                    ? ((item?.p - Math.min(...buyArr.map((i) => i.p))) /
                        (Math.max(...buyArr.map((i) => i.p)) -
                          Math.min(...buyArr.map((i) => i.p)))) *
                      100
                    : ((item?.p - Math.min(...sellArr.map((i) => i.p))) /
                        (Math.max(...sellArr.map((i) => i.p)) -
                          Math.min(...sellArr.map((i) => i.p)))) *
                      100;

                return (
                  <div
                    key={index}
                    style={{
                      position: 'relative',
                      overflow: 'hidden',
                    }}
                    className="pt-[2px] pb-[2px] "
                    onClick={() => {}}
                  >
                    <div
                      style={{
                        position: 'absolute',
                        right: 0,
                        top: 0,
                        bottom: 0,
                        width: gradientLength,
                        background:
                          item.type === 'buy'
                            ? 'rgba(144,238,144,0.05)'
                            : 'rgba(255,0,0,0.05)',
                      }}
                    />
                    <div className="flex justify-between mb-[5px]">
                      <div
                        className={`text-xs  w-[50px] flex-1 ${
                          item.type === 'buy'
                            ? 'text-successColor'
                            : 'text-errorColor'
                        }`}
                      >
                        {item.type === 'buy' ? `${t('买')}` : `${t('卖')}`}
                      </div>

                      <div className="mb0  flex-1">
                        <ValueDisplay
                          propsColor={
                            item.type === 'buy' ? '#3a8e7f' : '#f23645'
                          }
                          className="text-xxs my-[0] !font-[500]"
                          value={item?.p}
                          len={LocalChartInfo?.priceAccuracy}
                          isSubLen={false}
                        />
                      </div>

                      <div className="text-xxs text-center flex justify-end items-center  flex-1">
                        {RenderUtil.shortenNumber(
                          RenderUtil.formatNum(
                            item?.n,
                            transactionAccuracy ?? item?.n < 1 ? 5 : 2,
                          ),
                        )}
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
