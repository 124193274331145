import { IProps } from '@/components/base/baseModal';
import BaseTipModal from '@/components/base/baseTipModal';
import FeaturesRenderText from '@/components/features/featuresRenderText';
import PageEnum from '@/enums/pageEnum';
import { history } from '@@/core/history';
import { Button } from '@nextui-org/react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

interface HomeNotice extends Partial<IProps> {
  data: any;
  onCloseModal: () => void;
}

export function HomeNotice(props: HomeNotice) {
  const { data, onCloseModal, isOpen, onClose } = props;
  const [next, setNext] = useState(0);
  const total = data?.length - 1;
  const { t } = useTranslation();

  return (
    <>
      <BaseTipModal
        isOpen={isOpen as boolean}
        onClose={onClose as any}
        title={t('通知公告')} 
      >
        <div>
          <div className="flex justify-between items-center w-full mt-9">
            <div className="w-[80%] text-base font-bold truncate ">
              {data[next]?.title}
            </div>
            <div>
              <Button
                color="primary"
                variant="light"
                onClick={() => {
                  history.push(`${PageEnum.ANNOUNCEMENTDETAIL}?`, {
                    id: data[next]?.id,
                    isSendRequest: true,
                  });
                  onCloseModal && onCloseModal();
                  setNext(1);
                }}
              >
                {t('查看')}
              </Button>
            </div>
          </div>
          <div className=" max-h-[264px] overflow-y-scroll ">
            {data?.map((item: any, index: number) => (
              <>
                {index === next && (
                  <FeaturesRenderText key={item.id} data={item} />
                )}
              </>
            ))}
          </div>
          <div className="flex justify-end items-center p-1">
            {next !== 0 && (
              <Button
                color="primary"
                variant="light"
                onClick={() => setNext((prevState) => prevState - 1)}
              >
                {t('上一条')}
              </Button>
            )}
            {next < total && (
              <Button
                color="primary"
                variant="light"
                onClick={() => {
                  setNext((prevState) => prevState + 1);
                  console.log(next, '===', total);
                }}
              >
                {t('下一条')}
              </Button>
            )}
          </div>
        </div>
      </BaseTipModal>
    </>
  );
}
