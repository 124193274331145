import { GetCoinListTypeEnum } from '@/enums/businessEnum';
import useUrlState from '@ahooksjs/use-url-state';
import CryptocurrencyPcTrading from './CryptocurrencyPcTrading';
import PcContract from './PcContract';
import PcFastContract from './PcFastContract';

export default () => {
  const [urlState] = useUrlState<{
    type:GetCoinListTypeEnum
  }>({
    type: GetCoinListTypeEnum.CONTRACT
  });
  // 标记是合约交易还是BB交易
  const { type = GetCoinListTypeEnum.CONTRACT } = urlState || {};
  return (
    <>
      {/*极速合约*/}
      {type === GetCoinListTypeEnum.FASTCONTRACT && (
        <PcFastContract />
      )}

      {/*合约交易*/}
      {type === GetCoinListTypeEnum.CONTRACT && (
        <PcContract  />
      )}

      {/*币币/现货交易*/}
      {type === GetCoinListTypeEnum.COIN && (
        <CryptocurrencyPcTrading  />
      )}
    </>
  );
};

interface IProps {
  reloadOrder: () => void;
  type?: any;
}
