import { SvgSpinnersBarsScale } from '@/assets/icons/layout/SvgSpinnersBarsScale';
import BaseWithErrorBoundary from '@/components/base/baseWithErrorBoundary';
import ThemeEnum from '@/enums/themeEnum';
import { useTheme } from 'next-themes';
import { useEffect, useState } from 'react';
//图表的加载层
const LoadingOverlay = ({ children }: any) => {
  const [isLoading, setIsLoading] = useState(true);

  const { theme } = useTheme();

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 800);
    return () => clearTimeout(timer);
  }, []);

  return (
    <div className="h-full relative">
      {isLoading && (
        <div
          className={`absolute top-0 left-0 w-full h-full flex items-center justify-center z-[10]
            ${
              theme === ThemeEnum.light
                ? ' bg-backgroundAuxiliaryColor'
                : ' bg-backgroundAuxiliaryColor]'
            }
          `}
        >
          <SvgSpinnersBarsScale className=" text-primary text-2xl" />
        </div>
      )}
      {children}
    </div>
  );
};

export default BaseWithErrorBoundary(LoadingOverlay);
