import type { SVGProps } from 'react';

export function AITRADE(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="19"
      height="18"
      viewBox="0 0 19 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_203_2303)">
        <path
          d="M1.4 8.1001V12.6001C1.28181 12.6001 1.16478 12.5768 1.05558 12.5316C0.946392 12.4864 0.847177 12.4201 0.763604 12.3365C0.680031 12.2529 0.613738 12.1537 0.568508 12.0445C0.523279 11.9353 0.5 11.8183 0.5 11.7001V9.0001C0.5 8.7614 0.594821 8.53249 0.763604 8.3637C0.932387 8.19492 1.16131 8.1001 1.4 8.1001ZM17.6 8.1001C17.8387 8.1001 18.0676 8.19492 18.2364 8.3637C18.4052 8.53249 18.5 8.7614 18.5 9.0001V11.7001C18.5 11.9388 18.4052 12.1677 18.2364 12.3365C18.0676 12.5053 17.8387 12.6001 17.6 12.6001V8.1001Z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M2 7C2 6.44772 2.44772 6 3 6H16C16.5523 6 17 6.44772 17 7V15C17 15.5523 16.5523 16 16 16H3C2.44772 16 2 15.5523 2 15V7ZM5 10C5 9.44772 5.44772 9 6 9H7C7.55228 9 8 9.44772 8 10V11C8 11.5523 7.55228 12 7 12H6C5.44772 12 5 11.5523 5 11V10ZM12 9C11.4477 9 11 9.44772 11 10V11C11 11.5523 11.4477 12 12 12H13C13.5523 12 14 11.5523 14 11V10C14 9.44772 13.5523 9 13 9H12Z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10 4.79198C10.883 4.4062 11.5 3.52516 11.5 2.5C11.5 1.11929 10.3807 0 9 0C7.61929 0 6.5 1.11929 6.5 2.5C6.5 3.52516 7.11705 4.4062 8 4.79198V6H10V4.79198Z"
          fill="hsl(var(--nextui-primary)"
        />
      </g>
      <defs>
        <clipPath id="clip0_203_2303">
          <rect
            width="18"
            height="18"
            fill="transparent"
            transform="translate(0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
