import { CarbonCloseOutline } from '@/assets/icons/comm/CarbonCloseOutline';
import { LoadingSvg } from '@/assets/icons/comm/LoadingSvg';
import BaseInputNumber from '@/components/base/baseInputNumber';
import ValueDisplay from '@/components/display/displayValueDisplay';
import RenderUtil from '@/utils/RenderUtil';
import { Button, Chip, ScrollShadow, cn } from '@nextui-org/react';
import { useModel } from '@umijs/max';
import { useMount } from 'ahooks';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

/**
 * 极速合约
 */
export default function TradeForm(props: any) {
  const {
    isBuy,
    submitLoading,
    onSubmitRequest,
    onClose,
    contentMaxHeight = '65vh',
  } = props || {};

  const { t } = useTranslation();

  const { fastWallet } = useModel('user');
  const { getSocketRowByName } = useModel('socket');
  const { localFastContract } = useModel('contract');

  const soketData = getSocketRowByName(localFastContract?.symbol);

  //USDT 数量
  const [minNum, setMinNum] = useState(0.01);
  // 金额
  const [num, setNum] = useState(100);
  // 选择的时间
  const [currentPosition, setCurrentPosition] = useState(0);
  // 预估 盈利
  const [profit, setProfit] = useState(0);

  useMount(() => {});

  // 是否休市
  const isClose = false;

  // 红涨绿跌设置
  const {
    currentUpClass,
    currentDownClass,
    currentDownBgClass,
    currentUpBgClass,
  } = useModel('system');
  // 拿到当前是否是PC
  const { isMobile } = useModel('system');

  // 提交表单
  const onSubmit = (buyOrder?: any) => {
    onSubmitRequest({
      symbolContractQuickId: localFastContract?.id,
      // 方向
      direction: buyOrder ? '1' : '2',
      // 金额
      amount: num,
      // 时间倒计时
      tradingTime: currentPosition,
    });
  };

  // 配置的时间列表
  //极速合约时间 选择
  const tradingTimeConfigList = localFastContract?.tradingTimeConfigList ?? [];

  useEffect(() => {
    if (currentPosition === 0 && tradingTimeConfigList?.length) {
      setCurrentPosition(tradingTimeConfigList?.[0]?.time);
      setNum(tradingTimeConfigList?.[0]?.limitAmount);
      setMinNum(tradingTimeConfigList?.[0]?.limitAmount);
      if (isBuy) {
        setProfit(tradingTimeConfigList?.[0]?.upProfit);
      } else {
        setProfit(tradingTimeConfigList?.[0]?.downProfit);
      }
    }
  }, [tradingTimeConfigList]);

  return (
    <div className="pt-3 text-foreground px-4 h-full flex flex-col justify-between overflow-auto sm:max-w-[330px]">
      <div>
        <div className="flex justify-between items-center">
          <div className="font-bold text-base">{t('极速合约')}</div>
          {isMobile && (
            <CarbonCloseOutline
              className="text-xl cursor-pointer"
              onClick={(e) => {
                e.stopPropagation();
                e.defaultPrevented = false;
                onClose?.();
                return false;
              }}
            />
          )}
        </div>

        <ScrollShadow
          hideScrollBar
          className="pt-[10px]"
          style={{ maxHeight: contentMaxHeight }}
        >
          <div className="py-2 text-xs pt-2">
            <div className="pb-4 pt-1 flex justify-between items-center">
              <div className="flex items-center">
                {isMobile && (
                  <div>
                    <div className={`text-base font-bold`}>
                      <span>{isBuy ? t('买涨') : t('买跌')}</span>
                      <span
                        className={`${
                          isBuy ? currentUpClass : currentDownClass
                        } ml-2 text-base font-bold`}
                      >
                        {localFastContract?.alias}
                      </span>
                    </div>
                  </div>
                )}
                <div className="ml-1">
                  {localFastContract?.vip === 1 && (
                    <Chip size="sm" variant="faded" color="success">
                      VIP
                    </Chip>
                  )}
                </div>
              </div>
            </div>

            <div className="flex justify-between pb-3">
              <div className=" text-auxiliaryTextColor">
                {t('最新价格')} ({localFastContract?.coinAlias})
              </div>
              <div className=" font-bold">
                <ValueDisplay
                  value={soketData?.a}
                  len={2}
                  suffix={' ' + localFastContract?.coinAlias}
                />
              </div>
            </div>

            <div className="flex justify-between pb-3">
              <div className=" text-auxiliaryTextColor">{t('当日涨幅')}</div>
              <div className=" font-bold">
                <ValueDisplay value={soketData?.pe} len={2} ratio />
              </div>
            </div>

            <div className="flex justify-between pb-3">
              <div className=" text-auxiliaryTextColor">{t('可用资产')}</div>
              <div className=" font-bold">
                <ValueDisplay
                  value={fastWallet?.walletBalance}
                  len={2}
                  suffix={' ' + localFastContract?.coinAlias}
                />
              </div>
            </div>

            <div className="pb-4 pt-2">
              <div className="font-bold">
                {t('交易金额')} {' (' + localFastContract?.coinAlias + ')'}
              </div>
              <div className="font-bold pb-2"></div>

              <div className="bg-backgroundAuxiliaryColor rounded-md flex items-center">
                <BaseInputNumber
                  onBlur={() => {
                    if (num < minNum) {
                      setNum(minNum);
                    }
                  }}
                  value={num}
                  min={minNum}
                  step={1}
                  max={fastWallet.walletBalance}
                  onChange={(value) => {
                    if (value < fastWallet.walletBalance) setNum(value);
                  }}
                  className="flex-1"
                />
              </div>
            </div>
          </div>
          <div className=" font-bold">{t('交易时间')}</div>
          {/* 时间 */}
          <div className="grid grid-cols-4 gap-3 pt-2 pb-3">
            {tradingTimeConfigList?.map((item: any) => {
              return (
                <div
                  key={item?.time}
                  onClick={() => {
                    setMinNum(item?.limitAmount);
                    setNum(item?.limitAmount);
                    setCurrentPosition(item?.time);
                    if (isBuy) {
                      setProfit(item?.upProfit);
                    } else {
                      setProfit(item?.downProfit);
                    }
                  }}
                  className={cn(
                    'border-1  text-center rounded-md text-sm py-[3px]',
                    {
                      'border-primary': currentPosition === item?.time,
                      'border-backgroundAuxiliaryColor':
                        currentPosition !== item?.time,
                    },
                  )}
                >
                  {item?.time} {t('1000')}
                </div>
              );
            })}
          </div>

          <div className="flex pb-3 text-xs">
            <div className=" text-auxiliaryTextColor">{t('最小购买金额')}</div>
            <div className=" font-bold ml-3">
              {RenderUtil.FormatAmount(minNum)} {localFastContract?.coinAlias}
            </div>
          </div>

          <div className="flex justify-between text-xs border-backgroundAuxiliaryColor border-1 py-6 px-2 rounded-md items-center">
            <div className=" text-auxiliaryTextColor text-[14px] flex items-center">
              <img
                src={require('@/assets/img/trade/yinli.png')}
                className="w-[18px] h-[18px] mr-1"
                alt=""
              />
              {t('盈利率')}
            </div>
            <div className=" font-bold text-xl">
              <ValueDisplay
                value={profit}
                len={2}
                ratio
                fontSize={20}
                propsColor="#2db880"
              />
            </div>
          </div>

          {/* pc 按钮 */}

          {!isMobile && (
            <div className="flex items-center gap-3 mt-3">
              <Button
                spinner={<LoadingSvg />}
                isLoading={submitLoading}
                onClick={() => {
                  onSubmit(true);
                }}
                className={`${currentUpBgClass} w-full my-2 text-[#fff] rounded-md`}
              >
                <div className="text-xs flex justify-between items-center">
                  <img
                    src={require('@/assets/img/trade/long.png')}
                    className="w-[12px] h-[12px] mr-1"
                  />

                  <div>{t('买涨')}</div>
                </div>
              </Button>
              <Button
                spinner={<LoadingSvg />}
                isLoading={submitLoading}
                onClick={() => {
                  onSubmit(false);
                }}
                className={`${currentDownBgClass} w-full  text-[#fff] rounded-md`}
              >
                <div className="text-xs flex items-center">
                  <img
                    src={require('@/assets/img/trade/short.png')}
                    className="w-[12px] h-[12px] mr-1"
                  />

                  <div>{t('买跌')}</div>
                </div>
              </Button>
            </div>
          )}
        </ScrollShadow>
      </div>

      {isClose && (
        <Button disabled className="w-full my-2 rounded-md">
          <div className="text-xs">
            <div>{t('休市中')}</div>
          </div>
        </Button>
      )}

      {/* 手机按钮 */}
      {!isClose && (
        <>
          {isMobile && (
            <>
              {isBuy ? (
                <Button
                  spinner={<LoadingSvg />}
                  isLoading={submitLoading}
                  onClick={() => {
                    onSubmit(true);
                  }}
                  className={`${currentUpBgClass} w-full my-2 text-[#fff] rounded-md`}
                >
                  <div className="text-xs">
                    <div>{t('买涨')}</div>
                  </div>
                </Button>
              ) : (
                <Button
                  spinner={<LoadingSvg />}
                  isLoading={submitLoading}
                  onClick={() => {
                    onSubmit(false);
                  }}
                  className={`${currentDownBgClass} w-full my-2  text-[#fff] rounded-md`}
                >
                  <div className="text-xs">
                    <div>{t('买跌')}</div>
                  </div>
                </Button>
              )}
            </>
          )}
        </>
      )}
    </div>
  );
}
