import BaseTabs from '@/components/base/baseTabs';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import PcCoinLeft from './PcCoinLeft';
import PcCoinRight from './PcCoinRight';

export default () => {
  //直接引入交易页面状态，然后扩展功能，这里就是扩展reload

  const { t } = useTranslation();

  const [priceType, setPriceType] = useState('1');
  const priceTypeOption = [
    {
      text: t('市价交易'),
      value: '1',
    },
    {
      text: t('限价交易'),
      value: '2',
    },
  ];

  return (
    <>
      <div className="pt-4">
        <BaseTabs
          value={priceType}
          onChange={setPriceType}
          options={priceTypeOption}
          classNames={{ active: ' text-pcPrimary' }}
        />

        <div className="flex justify-between space-x-2 pt-3">
          <PcCoinLeft priceType={priceType}  />
          <div className="border-r-1 border-borderColor border-dashed w-[1px]"></div>
          <PcCoinRight priceType={priceType} />
        </div>
      </div>
    </>
  );
};

interface IProps {
  reloadOrder: () => void;
}
