import BaseWithErrorBoundary from '@/components/base/baseWithErrorBoundary';
import { GetCoinListTypeEnum } from '@/enums/businessEnum';
import { getUnreadNotice } from '@/services/api/requestApi';
import { useReq } from '@/services/net/request';
import useUrlState from '@ahooksjs/use-url-state';
import { cn } from '@nextui-org/react';
import { useModel } from '@umijs/max';
import { useInViewport, useMount } from 'ahooks';
import { useRef, useState } from 'react';
import { HomeNotice } from './components/HomeNotice';
import HomePcChartBox from './components/HomePcChartBox';
import HomePcChartHead from './components/HomePcChartHead';
import HomePcCoinSearch from './components/HomePcCoinSearch';
import HomePcOrder from './components/HomePcOrder';
import PcTransaction from './components/HomePcTransaction';
import PcCoinRightBox from './components/PcCoinRightBox';
import PcContractRightBox from './components/PcContractRightBox';

/**
 * PC 版本首页
 */
export default BaseWithErrorBoundary(() => {
  // 基础定义
  const [isOpen, setIsOpen] = useState(false);
  const { requestUserInfo } = useModel('user');
  const [urlState] = useUrlState<any>({});
  const { type = GetCoinListTypeEnum.CONTRACT } = urlState || {};
  const viewRef = useRef<HTMLDivElement>(null);

  const [inViewport] = useInViewport(viewRef);
  const { orderKey: globKey } = useModel('system');

  // 获取未读公告
  const { runAsync: getNoticeUnread, data: noticeData = [] as any } = useReq(
    () => getUnreadNotice(),
    {
      formatResult: (res: any) => {
        return res?.data ?? [];
      },
    },
  );

  useMount(() => {
    requestUserInfo();
    if (type && type === '1') {
      getNoticeUnread();
    }
  });

  const { LocalChartInfo } = useModel('kline');

  return (
    <div
      className="max-w-[1700px] mx-auto select-none  overflow-y-auto "
      key={type}
      ref={viewRef}
    >
      <>
        <div className=" flex justify-center m-auto  w-full">
          <div className=" flex-1 flex border-t border-backgroundAuxiliaryColor">
            {/*/!* 左侧行情面板*!/*/}
            <div className="border-r border-backgroundAuxiliaryColor p-1 w-[350px] max-h-[100vh] overflow-y-scroll">
              <HomePcCoinSearch ></HomePcCoinSearch>
            </div>

            {/* 中间面板 */}
            <div className="px-2 flex-1 select-none">
              <HomePcChartHead></HomePcChartHead>

              <HomePcChartBox
                height={type === GetCoinListTypeEnum.COIN ? '35vh' : '33vh'}
                len={LocalChartInfo?.priceAccuracy ?? 6}
              />

              <div className="select-none">
                {/* // 币币 & 合约 的 交易下单面板 */}
                <PcTransaction />
              </div>
            </div>
          </div>

          {/* 右侧 交易数据 面板 */}
          <div
            className={cn(
              'border-l select-none border-backgroundAuxiliaryColor w-[380px] p-4',
              {
                '!w-[480px]': type === GetCoinListTypeEnum.COIN,
              },
            )}
          >
            {type === GetCoinListTypeEnum.COIN ? (
              <PcCoinRightBox num={99} key={type} />
            ) : (
              <PcContractRightBox num={99} key={type} />
            )}
          </div>
          <HomeNotice
            data={noticeData}
            isOpen={isOpen}
            onCloseModal={() => {
              setIsOpen(false);
            }}
            onClose={() => {
              setIsOpen(false);
            }}
          />
        </div>
        {
          inViewport &&
          <div key={type === GetCoinListTypeEnum.CONTRACT ? undefined : globKey}>
            <HomePcOrder />
          </div>
        }
      </>
    </div>
  );
});
