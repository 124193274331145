import BaseModal from '@/components/base/baseModal';
import { Button, RadioGroup } from '@nextui-org/react';
import { useModel } from '@umijs/max';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CustomRadio } from './CustomRadio';

// 定义枚举类型来表示ID
export const OrderTypeEnum = {
  QUANTITY_ORDER: '1', // 按数量下单
  VALUE_ORDER: '2', // 按价值下单
  MARGIN_ORDER: '3', // 按保证金下单
  ZHANG_ORDER: '4',   //按张下单
};

/**
 *  合约 弹框
 */
export default function (props: any) {
  const { typeModel, buyType, setBuyType } = props || {};
  const { t } = useTranslation();
  const { localContract } = useModel('contract');

  const list = [
    {
      id: OrderTypeEnum.QUANTITY_ORDER,
      title: t('按数量下单'),
      subTitle: t('请填写合约数量，单位') + (localContract?.baseSymbol ?? ''),
      type: localContract?.baseSymbol,
    },
    {
      id: OrderTypeEnum.VALUE_ORDER,
      title: t('按价值下单'),
      subTitle: t('请填写订单价值，可通过调整杠杆修改下单所需保证金'),
      type: localContract?.coinAlias ?? '',
    },
    {
      id: OrderTypeEnum.MARGIN_ORDER,
      title: t('按保证金下单'),
      subTitle: t('请填写订单成本，修改杠杆不会改变成本。'),
      type: localContract?.coinAlias ?? '',
    },
  ];

  const [selected, setSelected] = useState(buyType);

  return (
    <BaseModal
      classNames={{ modal: 'h-min-[60vh]' }}
      isOpen={typeModel.isOpen}
      onClose={typeModel.onClose}
    >
      <div className="px-4">
        <div className="text-center font-bold text-base">{t('合约类型')}</div>

        <div className="pt-4">
          <RadioGroup
            value={selected}
            onChange={(e) => {
              setSelected(e.target.value);
            }}
          >
            {list.map((item: any) => {
              return (
                <CustomRadio key={item?.id} value={item?.id}>
                  <div className="text-xs">
                    <div className="text-base font-bold flex w-full">
                      <span>{item?.title}</span>
                      <span className=" ml-1"> ({item?.type})</span>
                    </div>
                    <div className=" text-auxiliaryTextColor font-normal pt-3">
                      {item?.subTitle}
                    </div>
                  </div>
                </CustomRadio>
              );
            })}
          </RadioGroup>
        </div>

        <div className="pt-3 font-bold text-[#FF7D00]">{t('注意')}</div>
        <div className="pt-1 mb-3 text-[#FF7D00] text-xs">
          {t(
            '您的订单数量将按照您填写的开仓成本计算。如遇到极端市场波动，您的订单委托可能会失败',
          )}
        </div>

        <div className="mb-3">
          <Button
            onClick={() => {
              setBuyType(selected);
              typeModel.onClose();
            }}
            className="mainColorButton !rounded-md h-[45px] !py-0 w-full mt-3"
            color="primary"
          >
            {t('确认')}
          </Button>
        </div>
      </div>
    </BaseModal>
  );
}
