import ValueDisplay from '@/components/display/displayValueDisplay';
import SortColumn from '@/components/features/featuresMarketList/SortColumn';
import {
  CollectEnum,
  GetCoinListTypeEnum,
  WalletTypeEnum,
} from '@/enums/businessEnum';
import PageEnum from '@/enums/pageEnum';
import { ChartTypeEnum } from '@/models/kline';
import RenderUtil from '@/utils/RenderUtil';
import { history } from '@@/core/history';
import { Image, cn } from '@nextui-org/react';
import { useModel } from '@umijs/max';
import { useUnmount } from 'ahooks';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

type IProps = {
  type?: string;
  search?: string;
  onClose?: any;
  list?: any;
  isTrad?: boolean;
  selected?: boolean;
  onClickItem?: (data?: any) => void;
  // 是否是币币
  isCoin?: boolean;
  //点击后是否跳转
  isJump?: boolean;
  //收藏里面子筛选 这里区分收藏里面子筛选需要展示不同的表头,如果直接使用type会打乱跳转逻辑--分别是合约/币币
  collectType?: GetCoinListTypeEnum;
};
// 市场组件
export default function HomePcMarketList({
  type,
  list,
  onClickItem,
  isJump = true,
}: IProps) {
  const { setCoinType, LocalChartInfo } = useModel('kline');
  const { getSocketRowByName, sendMsg, isSub } = useModel('socket');

  const { t } = useTranslation();
  const { setLocalCoin ,localCoin} = useModel('coin');
  const { setLocalContract, localContract,setLocalFastContract } = useModel('contract');

  useEffect(() => {
    if (list?.length) {
      sendMsg(
        `{"type":"SUB","data": ${JSON.stringify(
          list?.map((i: { symbol: any }) => i?.symbol),
        )}}`,
      );
    }
  }, [list, isSub]);

  const { isMobile ,setLocalChoiceContractSocket,localChoiceContractSocket} = useModel('system');

  const { sortState, setSortState, setIsPcSort } =
  useModel('system');
  // 更新排序状态
  // 更新排序状态
  const getlist = (id: number, sortOrder: 'asc' | 'desc' | null) => {
    let newSortState = { ...sortState };
    setIsPcSort(true); // 假设 isHomeSort 的作用是为了禁止重复设置它

    console.log(sortOrder, 'sortOrder');

    // 重置其他字段的排序为 0，并只更新当前点击的字段
    switch (id) {
      case 2: // 价格
        newSortState = {
          priceSort: sortOrder === 'asc' ? 1 : sortOrder === 'desc' ? -1 : 0, // asc:1, desc:-1, null:0
          upDownSort: 0, // 保留其他字段的排序状态
          upDownRangSort: 0,
        };
        break;

      case 3: // 涨跌幅
        newSortState = {
          priceSort: 0, // 保留其他字段的排序状态
          upDownSort: 0,
          upDownRangSort:
            sortOrder === 'asc' ? 1 : sortOrder === 'desc' ? -1 : 0, // asc:1, desc:-1, null:0
        };
        break;

      case 1: // 名称
        newSortState = {
          priceSort: 0, // 保留其他字段的排序状态
          upDownSort: sortOrder === 'asc' ? 1 : sortOrder === 'desc' ? -1 : 0, // asc:1, desc:-1, null:0
          upDownRangSort: 0,
        };
        break;

      default:
        break;
    }

    // 更新排序状态
    setSortState(newSortState);

    // 将排序状态传递给后端或用于其他逻辑
  };

  // 设置 游览器标签 title
  useEffect(()=>{
    // 合约
      if(type === GetCoinListTypeEnum.CONTRACT){
      setLocalChoiceContractSocket(localContract)
      }
      // 币币/现货
      else if(type === GetCoinListTypeEnum.COIN){
      setLocalChoiceContractSocket(localCoin)
        
      }
    
  },[type,localContract,localCoin])
  
  // 取消订阅
  useUnmount(()=>{
    setLocalChoiceContractSocket(null)
  })
  // 主体布局
  return (
    <div>
      <div className="my-2 flex justify-between text-auxiliaryTextColor text-[11px] px-2.5">
        {/* <div className="flex-1 ">{t('币种')}</div>
        <div className="flex-1  text-right mr-4">{t('最新价')}</div>
        <div className="text-right flex-1">{t('涨幅比')}</div> */}
         <div className="flex-1">
          <SortColumn
            id={1}
            label={t('币种')}
            onSort={getlist}
            active={sortState.upDownSort !== null} // 如果不为 0，则表示激活状态
            sortOrder={
              sortState.upDownSort === 1
                ? 'asc'
                : sortState.upDownSort === -1
                ? 'desc'
                : null
            } // 根据状态显示升序、降序或未排序
          />
        </div>

        <div className="flex-1 pr-2">
          <SortColumn
            id={2}
            label={t('最新价')}
            className="justify-end"
            onSort={getlist}
            active={sortState.priceSort !== null}
            sortOrder={
              sortState.priceSort === 1
                ? 'asc'
                : sortState.priceSort === -1
                ? 'desc'
                : null
            }
          />
        </div>

        <div className="flex-1">
          <SortColumn
            id={3}
            label={t('涨幅比')}
            className="justify-end"
            onSort={getlist}
            active={sortState.upDownRangSort !== null}
            sortOrder={
              sortState.upDownRangSort === 1
                ? 'asc'
                : sortState.upDownRangSort === -1
                ? 'desc'
                : null
            }
          />
        </div>
      </div>

      <div className="justify-between">
        {list?.map((item: any) => {
          const soketData = getSocketRowByName(item.symbol);
          return (
            <div
              key={item.name}
              className={cn(
                `flex py-1 px-2  items-center justify-between w-full  cursor-pointer  mb-1`,
                {
                  'bg-backgroundAuxiliaryColor':
                    LocalChartInfo?.id && LocalChartInfo?.id === item?.id,
                },
              )}
              onClick={() => {
                // 极速合约
                if (type === GetCoinListTypeEnum.FASTCONTRACT) {
                  setCoinType(ChartTypeEnum.FAST_CONTRACT);
                  setLocalFastContract(item);
                  if (!isMobile) {
                    isJump &&
                      history.push(
                        PageEnum.HOME + '?type=' + WalletTypeEnum.FASTCONTRACT,
                      );
                    return;
                  }
                  // h5
                  isJump && history.replace(PageEnum.CONTRACT);
                }

                // 合约跳转
                else if (type === GetCoinListTypeEnum.CONTRACT) {
                  setCoinType(ChartTypeEnum.CONTRACT);
                  setLocalContract(item);
                  if (!isMobile) {
                    isJump &&
                      history.push(
                        PageEnum.HOME + '?type=' + WalletTypeEnum.CONTRACT,
                      );
                    return;
                  }
                  isJump && history.replace(PageEnum.CONTRACT);
                }
                // 币币跳转
                else if (type === GetCoinListTypeEnum.COIN) {
                  setCoinType(ChartTypeEnum.COIN);
                  setLocalCoin(item);
                  if (!isMobile) {
                    isJump &&
                      history.push(
                        PageEnum.HOME + '?type=' + WalletTypeEnum.COIN,
                      );
                    return;
                  }
                  isJump && history.replace(PageEnum.TRANSACTION);
                }

                // 收藏跳转
                else if (type === GetCoinListTypeEnum.COLLECT) {
                  // 币币收藏
                  if (item?.isTripartite === CollectEnum.COIN_COLLECT) {
                    setCoinType(ChartTypeEnum.COIN);
                    setLocalCoin({ ...item, id: item?.sourceId });
                    if (!isMobile) {
                      isJump &&
                        history.push(
                          PageEnum.HOME + '?type=' + WalletTypeEnum.COIN,
                        );
                      return;
                    }
                    isJump && history.replace(PageEnum.TRANSACTION);
                  }
                  // 合约收藏
                  if (item?.isTripartite === CollectEnum.CONTRACT_COLLECT) {
                    setCoinType(ChartTypeEnum.CONTRACT);
                    setLocalContract({
                      coinAlias: 'USDT',
                      ...item,
                      id: item?.sourceId,
                    });
                    if (!isMobile) {
                      isJump &&
                        history.push(
                          PageEnum.HOME + '?type=' + WalletTypeEnum.CONTRACT,
                        );
                      return;
                    }
                    isJump && history.replace(PageEnum.CONTRACT);
                  }

                  //  快速合约
                  if (
                    item?.isTripartite === CollectEnum.FAST_CONTRACT_COLLECT
                  ) {
                    setCoinType(ChartTypeEnum.FAST_CONTRACT);
                    setLocalFastContract({
                      quickContract: true,
                      coinAlias: 'USDT',
                      ...item,
                      id: item?.sourceId,
                    });
                    if (!isMobile) {
                      isJump &&
                        history.push(
                          PageEnum.HOME +
                            '?type=' +
                            WalletTypeEnum.FASTCONTRACT,
                        );
                      return;
                    }
                    isJump && history.replace(PageEnum.CONTRACT);
                  }
                }
                //便于扩展
                onClickItem?.(item);
              }}
            >
              {/* 第一列 */}
              <div className="flex-1  font-bold  flex justify-start text-sm items-center truncate">
                <div className="w-[30px]">
                  {!item?.icon2 && (
                    <Image
                      className=" max-w-[25px] max-h-[25px] mr-2 flex-shrink-0 rounded-full object-cover"
                      src={item?.icon1}
                    />
                  )}
                  {item?.icon2 && (
                    <div className="w-[18px] h-[18px] relative mr-2">
                      <Image
                        classNames={{
                          wrapper: '!max-w-[100%]',
                        }}
                        className="flex-shrink-0  object-cover  absolute left-[0] top-[-3px] z-[1]"
                        src={item?.icon1}
                      />
                      <Image
                        classNames={{
                          wrapper: '!max-w-[100%]',
                        }}
                        className="!w-[18px] h-[18px]  flex-shrink-0 rounded-full object-cover absolute left-[10px] top-0 z-[2]"
                        src={item?.icon2}
                      />
                    </div>
                  )}
                </div>

                <div className="truncate">{item?.company}</div>
                <div className="text-foreground break-words text-xs truncate">
                  {/*{item?.baseSymbol}*/}
                  {item?.alias}
                </div>
              </div>

              {/* 第二列 */}
              <div className="flex-1 text-right mr-4">
                <div className="text-[14px] font-[500]">
                  {RenderUtil.FormatAmount(
                    soketData?.a,
                    item?.priceAccuracy ?? 2,
                    true,
                  )}
                </div>
              </div>
              {/* 第三列 */}
              <div className="flex-1 text-right">
                <ValueDisplay
                  value={soketData.pe}
                  unit={soketData.pe > 0 ? '+' : ''}
                  ratio
                  className="text-[14px] !font-[500]"
                  fontSize={10}
                  justify="justify-end"
                />
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}
