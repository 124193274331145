import BaseInputNumber from '@/components/base/baseInputNumber';
import BaseSlideRange from '@/components/base/baseSlideRange';
import TransactionInformation from '@/pages/transaction/components/TransactionInformation';
import {
  TransactionModeEnum,
  TransactionTypeEnum,
} from '@/pages/transaction/type';

import {
  CoinTradeContext,
  createCoinModel,
} from '@/pages/transaction/coinModel';
import ProjectSetting from '@/setting/projectSetting';
import RenderUtil from '@/utils/RenderUtil';
import { useModel } from '@umijs/max';
import { useUpdateEffect } from 'ahooks';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
const parser = (value: string) => {
  if (!value) return value;
  return value?.replace(/\$\s?|(,*)/g, '');
};
export default ({ priceType }: IProps) => {
  const modelProps = createCoinModel();

  //直接引入交易页面状态，然后扩展功能，这里就是扩展reload
  const {
    price,
    setPrice,
    amount,
    onPriceChange,
    onAmountChange,
    transactionMode,
    orderKey,
  } = modelProps;

  const { walletFundReq } = useModel('user');

  const { orderKey: systemOrderKey } = useModel('system');
  // 使用state来追踪当前选中的值以及外部控制的显示/隐藏状态
  const { localCoin } = useModel('coin');
  const { getSocketRowByName } = useModel('socket');
  // 交易精度，后台控制值，输入框
  // 交易精度，后台控制值，输入框
  const transactionAccuracy = localCoin?.transactionAccuracy ?? 6;
  // 价格精度
  const priceAccuracyLength = localCoin?.priceAccuracy ?? 6;

  const { t } = useTranslation();
  const socketData = getSocketRowByName(localCoin?.symbol);
  const { coinsFunds } = useModel('user');
  // 限价的价格 或者行情价
  const memoPrice =
    +priceType === TransactionTypeEnum.LIMIT ? price : socketData?.a;

  // 手续费类型
  // 1 固定值：手续费
  // 2 百分比：买入->交易金额 * 手续费比例；卖出->交易金额 * 市价 * 手
  const commissionType = localCoin?.commissionType ?? 0;

  //手续费
  const commission = localCoin?.commission;

  // 一个手续费
  const onePremium = useMemo(() => {
    // 固定手续费 -》 买入 和 卖出 都一样
    if (commissionType === 1) {
      return commission;
    }
    // 比例 买入计算 -》当前价 * 手续费比例
    if (commissionType === 2 && transactionMode === 1) {
      return commission * 0.01;
    }
    // 比例 卖出计算 -》交易金额 * 市价 * 1手
    if (commissionType === 2 && transactionMode === 2) {
      return 1 * memoPrice * (commission * 0.01);
    }
    return 0;
  }, [
    commissionType,
    localCoin,
    commission,
    transactionMode,
    amount,
    socketData?.a,
  ]);

  // 获取可用金额
  const availableAmount = useMemo(() => {
    // 买入 交换币 -》基币钱包
    // 卖出 基币钱包 -》 交换币钱包
    if (
      localCoin &&
      coinsFunds?.walletList?.length !== 0 &&
      transactionMode === 1
    ) {
      return coinsFunds?.walletList?.find(
        (item: any) => item.coinId === localCoin?.exchangeId,
      );
    }
    if (
      localCoin &&
      coinsFunds?.walletList?.length !== 0 &&
      transactionMode === 2
    ) {
      return coinsFunds?.walletList?.find(
        (item: any) => item.coinId === localCoin?.baseId,
      );
    }
  }, [localCoin, coinsFunds, transactionMode]);

  // 最大手数 == 100% 的手数
  const maxLot = useMemo(() => {
    // 余额 减去手续费 除以  1手价钱 得到 100% 的手数
    if (transactionMode === 1) {
      if (commissionType === 1) {
        return availableAmount?.availableBalance;
      }
      // @ts-ignore
      return availableAmount?.availableBalance;
    }
    return availableAmount?.availableBalance;
  }, [availableAmount?.availableBalance, transactionMode, onePremium]);

  //仓位列表
  const [currentPosition, setCurrentPosition] = useState(0);

  //处理拉杆
  function handleSliderChange(value: any) {
    // 确保 userAmount 和 soketData 已经正确加载
    if (!availableAmount?.availableBalance || !socketData?.a) {
      return;
    }

    // 最大手数 * 拉杆值 *0.01
    // 购买 需要 / 行情
    let maxNum = (maxLot / memoPrice) * value * 0.01;

    // console.log(typeof transactionMode, 'transactionMode');
    // console.log(transactionMode, 'transactionMode');

    if (transactionMode === 2) {
      // 卖出不能 ÷ 行情
      maxNum = maxLot * (value * 0.01);
    }

    if (maxNum === 0 || maxNum <= 0) {
      onAmountChange(0);
    } else {
      // 设置最终计算的购买数量
      onAmountChange(Number(maxNum)?.toFixed?.(transactionAccuracy));
    }

    setCurrentPosition(value);
  }

  // 输入数量设置拉杆位置
  const onAmountChangeSetCurrentPosition = (value: any) => {
    if (!availableAmount?.availableBalance || !socketData?.a) {
      return;
    }
    // 最大手数 * 拉杆值 *0.01
    // 购买 需要 / 行情
    let maxNum = (maxLot / memoPrice) * 100 * 0.01;

    if (maxNum < 0.001) {
      maxNum = 0.01;
    }
    let currentPosition = value / maxNum * 100;
    if (currentPosition > 100) {
      currentPosition = 100;
    }
    if (currentPosition < 0) {
      currentPosition = 0;
    }
    setCurrentPosition(currentPosition);
  };

  //拿到当前是否是PC
  useUpdateEffect(() => {
    // 市价变化到 限价 时候，直接赋值到当前价
    if (+priceType === TransactionTypeEnum.LIMIT && socketData?.a) {
      onPriceChange(Number(socketData?.a)?.toFixed?.(priceAccuracyLength));
    }
  }, [priceType]);

  useEffect(() => {
    onAmountChange(0);
  }, []);

  useEffect(() => {
    if (+amount === 1) {
      handleSliderChange(amount);
    }
  }, [location.pathname, availableAmount?.availableBalance]);

  useUpdateEffect(() => {
    handleSliderChange(1);
  }, [transactionMode]);

  //最小购买
  //币币最小购买
  const { appInfo } = useModel('appInfo');
  const { COIN_MINIMUM_EXCHANGE_AMOUNT = 0.001 } = appInfo || {};
  const minBuyNum = useMemo(() => {
    return COIN_MINIMUM_EXCHANGE_AMOUNT / (socketData.a || 0.01);
  }, [COIN_MINIMUM_EXCHANGE_AMOUNT, socketData]);

  // 选择限价时 更改币种 修改价格
  useEffect(()=>{
    setPrice(socketData?.a);
  },[localCoin])


  // useEffect(() => {
  //   onAmountChange(0);
  //   setCurrentPosition(0)
  // }, [localCoin]);
  return (
    <CoinTradeContext.Provider value={modelProps}>
      <div className="flex-1">
        {/* //买入卖出 */}

        <div key={orderKey + transactionMode + systemOrderKey + priceType}>
          {/*左侧表单*/}
          <>
            {/*限价格*/}
            <div className="justify-between text-xs pt-1 pb-3">
              <span className="text-auxiliaryTextColor">{t('可用')}</span>
              <span className="text-backContrastColor ml-2">
                {RenderUtil.FormatAmount(availableAmount?.availableBalance, 2)}
                {ProjectSetting.APP_DEFAULT_CURRENCY}
              </span>
            </div>

            {/*限价单表单*/}
            {+priceType === TransactionTypeEnum.LIMIT && (
              <>
                <BaseInputNumber
                  title={t('价格')}
                  value={price}
                  len={priceAccuracyLength}
                  step={1 / Math.pow(10, priceAccuracyLength ?? 0)}
                  onChange={onPriceChange}
                />
              </>
            )}

            {/*市价单表单*/}
            {+priceType === TransactionTypeEnum.MARKET && (
              <>
                <div className="text-xs bg-backgroundAuxiliaryColor/80  h-[40px] flex items-center justify-center text-center rounded-[6px] my-2 py-2 select-none">
                  {t('以当前最优价格交易')}
                </div>
              </>
            )}
            <BaseInputNumber
              className="mt-5"
              title={t('数量')}
              value={amount}
              defaultValue=""
              showButton={false}
              placeholder={t("最小数量{{num}} {{coinName}}",{
                num: RenderUtil.FormatAmount(minBuyNum, 6, false),
                coinName: localCoin?.baseSymbol
              })}
              len={transactionAccuracy}
              step={1 / Math.pow(10, transactionAccuracy ?? 0)}
              onChange={(value: any) => {
                onAmountChange(value);
              }}
              onRealTimeInput={(value: any) => {
                onAmountChangeSetCurrentPosition(Number(parser(value)));
              }}
            />

            <div className="pt-5"></div>

            <div className="pt-1 overflow-visible px-1">
              <BaseSlideRange
                values={[1, 25, 50, 75, 100]}
                step={1}
                value={currentPosition}
                isShowButtonNumber={true}
                onChange={handleSliderChange}
              />
            </div>
            <div className="pt-5"></div>

            <div className="justify-between text-xs">
              <span className="text-auxiliaryTextColor">{t('交易额')}</span>
              <span className="text-backContrastColor ml-2">
                {RenderUtil.FormatAmount(amount * socketData?.a, 2, false)}
                {ProjectSetting.APP_DEFAULT_CURRENCY}
              </span>
            </div>
          </>
        </div>

        {/*交易信息 购买按钮*/}
        <TransactionInformation
          priceType={priceType}
          transactionMode={TransactionModeEnum.BUY}
          isBuyFull={false}
          onSuccess={() => {
            onAmountChange(0.01);
            walletFundReq();
          }}
        />
      </div>
    </CoinTradeContext.Provider>
  );
};

interface IProps {
  reloadOrder?: () => void;
  priceType?: any;
}
