import { SvgSpinnersBarsRotateFade } from '@/assets/icons/comm/SvgSpinnersBarsRotateFade';
import BaseTabs from '@/components/base/baseTabs';
import BaseWithErrorBoundary from '@/components/base/baseWithErrorBoundary';
import { GetCoinListTypeEnum } from '@/enums/businessEnum';
import PageEnum from '@/enums/pageEnum';
import CoinList from '@/pages/public/market/components/CoinList';
import ContractList from '@/pages/public/market/components/ContractList';
import StockList from '@/pages/public/market/components/stock/StockList';
import ProjectSetting from '@/setting/projectSetting';
import { cn } from '@/utils';
import { history, useModel } from '@umijs/max';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMount } from 'ahooks';
import { Divider } from '@nextui-org/react';

export default BaseWithErrorBoundary(() => {
  const { t } = useTranslation();
  const tabOptions = [
    { text: t('合约'), value: GetCoinListTypeEnum.CONTRACT },
    { text: t('现货'), value: GetCoinListTypeEnum.COIN },
    ProjectSetting.isShowStock
      ? { text: t('股票'), value: GetCoinListTypeEnum.STOCK }
      : null, // stock
  ].filter((i) => !!i);
  
  const { contractList, contractListLoading } = useModel('contract');
  const { coinListData, coinListLoading , setChoiceCoinSelected, choiceCoinSelected } = useModel('coin');
  const { stockListData, stockListLoading } = useModel('stock'); // 新增股票数据和加载状态
  const { setcoinSelected } = useModel('system');
  //默认选中 的
  const [tabSelected, setTabSelected] = useState(GetCoinListTypeEnum.CONTRACT);
  // 用于做外部加载
  const loadingDisplay = useMemo(() => {
    if (tabSelected === GetCoinListTypeEnum.COIN) {
      return coinListLoading;
    }
    if (tabSelected === GetCoinListTypeEnum.CONTRACT) {
      return contractListLoading;
    }
  }, [coinListLoading, contractListLoading]);

  // 主体布局
  return (
    <div className="min-h-[40vh]  overflow-hidden">
      <div >
        <div className="w-full flex flex-wrap items-center justify-between ">
        <div className='px-[10px] flex justify-between items-center w-full '>
                      <BaseTabs
            value={tabSelected}
            onChange={setTabSelected}
            options={tabOptions}
            tabsPropsProps={{
              classNames: {
                tab: '!text-[16px] !px-1 !h-[40px]',
                tabList: '!pb-0',
              },
            }}
          />
          {/* 加载 */}
          {loadingDisplay && (
            <div className="flex items-center justify-center w-fit h-full">
              <SvgSpinnersBarsRotateFade />
            </div>
          )}
          </div>
        <Divider className="!bg-backgroundAuxiliaryColor/80" />

        </div>
        <div className='w-full px-4 '>
        <div key={tabSelected}>
          {/*币币*/}
          <div
            className={cn(``, {
              'h-0 overflow-hidden': GetCoinListTypeEnum.COIN !== tabSelected,
            })}
          >
            <CoinList
              defaultList={coinListData}
              mode="simple"
              isHomeSort={true}
              setChoiceCoinSelected={setChoiceCoinSelected}
              choiceCoinSelected={choiceCoinSelected}
            />
          </div>

          <div
            className={cn(``, {
              'h-0 overflow-hidden':
                GetCoinListTypeEnum.CONTRACT !== tabSelected,
            })}
          >
            <ContractList
              defaultList={contractList}
              mode="simple"
              isHomeSort={true}
            />
          </div>

          {/*股票*/}
          <div
            className={cn(``, {
              'h-0 overflow-hidden': GetCoinListTypeEnum.STOCK !== tabSelected,
            })}
          >
            <StockList
              defaultList={stockListData}
              mode="simple"
              isHomeSort={true}
            />
          </div>
        </div>
        <div
          className="pt-3 text-center text-darkBrandColor text-sm14 cursor-pointer"
          onClick={() => {
            history.push(`${PageEnum.MARKET}?type=${tabSelected}`);
            setcoinSelected(choiceCoinSelected)
          }}
        >
          {t('查看更多')}
        </div>
        </div>
      </div>
    </div>
  );
});
