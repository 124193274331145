import { Component } from 'react';

class ErrorBoundary extends Component {
  constructor(props: any) {
    super(props);
    this.state = { hasError: false } as any;
  }

  static getDerivedStateFromError(error: any) {
    return { hasError: true };
  }

  componentDidCatch(error: any, errorInfo: any) {
    console.error('ErrorBoundary caught an error', error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      // 自定义的降级UI
      return <h2 className=" text-auxiliaryTextColor">-----error-----</h2>;
    }

    // 正常渲染子组件，并保持props透明传递
    return this.props.children;
  }
}

export default ErrorBoundary;
