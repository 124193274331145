import BaseWithErrorBoundary from '@/components/base/baseWithErrorBoundary';
import TradingViewChart from '@/components/features/featuresTradingViewChart';
import { GetCoinListTypeEnum } from '@/enums/businessEnum';
import useUrlState from '@ahooksjs/use-url-state';
import { useModel } from '@umijs/max';
import { useTheme } from 'next-themes';
import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import LoadingOverlay from './HomeLoadingOverlay';

/**
 * 图表组件
 */
export default BaseWithErrorBoundary(
  ({ len = 2, coinType = 0, height = 280 }) => {
    //合约对象

    //图表相关
    /**
     * setInterval 设置图表时间分辨率
     * setKlineData 设置图表数据
     * reloadChart 刷新
     */
    const { interval, timeString, setInterval, supported_resolutions } =
      useModel('kline');

    const { localContract, localFastContract } = useModel('contract');
    const { localCoin } = useModel('coin');
    const [urlState] = useUrlState<any>({});
    // 标记是合约交易还是BB交易
    const { type = GetCoinListTypeEnum.CONTRACT } = urlState || {};
    // 渲染本地符号数据
    const renderLocalChart = useMemo(() => {
      // 渲染本地符号数据

      if (type === GetCoinListTypeEnum.FASTCONTRACT) {
        return localFastContract;
      }

      if (type === GetCoinListTypeEnum.CONTRACT) {
        return localContract;
      } else if (type === GetCoinListTypeEnum.COIN) {
        return localCoin;
      }
    }, [type, localContract, localCoin, localFastContract]);

    //主题
    //图表语言
    const { chartlanguage } = useModel('language');
    //币种对象
    const resolutions = supported_resolutions;

    const { theme } = useTheme();

    const coinName = renderLocalChart?.alias ?? renderLocalChart?.name;

    const chartProps = {
      interval: interval, //这个在变量里面 时间选择
      theme, //dark |light
      symbol: renderLocalChart?.symbol,
      title: coinName,
      locale: chartlanguage,
      socketSymbol: 'x',
      len: len,
      favorites: {
        intervals: resolutions,
      },
      disabled_features: [
        //禁用的一些侧边栏
        'header_symbol_search',
        'header_compare',
        'symbol_search_hot_key',
        'compare_symbol',
        'symbol_info',
        'go_to_date',
        'timeframes_toolbar',
        'save_chart_properties_to_local_storage',
        'volume_force_overlay',
        'left_toolbar',
        'header_saveload',
        // 'header_resolutions', //时间选择
        'header_undo_redo', //返回，撤销
        'header_indicators', // 技术指标
        // 'header_fullscreen_button', //全屏
        'header_screenshot', //保存按钮
        // 'header_widget',
      ],
    };

    useEffect(() => {
      if ([1, 3, 4, 5, 6].includes(coinType)) {
        setInterval('1D');
      }
    }, [coinType]);

    const { t } = useTranslation();

    return (
      <div id="chart-view" className=" w-full">
        <div className=" overflow-hidden" style={{ height }}>
          <LoadingOverlay>
            <TradingViewChart
              key={
                interval + chartlanguage + timeString + theme + coinName + len
              }
              chartProperties={chartProps}
              chartType={0}
              className="px-3"
              len={len}
            />
          </LoadingOverlay>
        </div>
      </div>
    );
  },
);
