import BaseInputNumber from '@/components/base/baseInputNumber';
import BaseSlideRange from '@/components/base/baseSlideRange';
import TransactionInformation from '@/pages/transaction/components/TransactionInformation';
import {
  TransactionModeEnum,
  TransactionTypeEnum,
} from '@/pages/transaction/type';

import {
  CoinTradeContext,
  createCoinModel,
} from '@/pages/transaction/coinModel';
import ProjectSetting from '@/setting/projectSetting';
import RenderUtil from '@/utils/RenderUtil';
import { useModel } from '@umijs/max';
import { useUpdateEffect } from 'ahooks';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

const parser = (value: string) => {
  if (!value) return value;
  return value?.replace(/\$\s?|(,*)/g, '');
};
export default ({ priceType }: IProps) => {
  const modelProps = createCoinModel();

  //直接引入交易页面状态，然后扩展功能，这里就是扩展reload
  const { price, setPrice,amount, onPriceChange, onAmountChange, orderKey } = modelProps;

  const transactionMode: any = TransactionModeEnum.SELL;

  const { walletFundReq } = useModel('user');

  // 使用state来追踪当前选中的值以及外部控制的显示/隐藏状态
  const { orderKey: systemOrderKey } = useModel('system');
  const { localCoin } = useModel('coin');
  const { getSocketRowByName } = useModel('socket');
  const { t } = useTranslation();
  const socketData = getSocketRowByName(localCoin?.symbol);
  const { coinsFunds, renderFunds } = useModel('user');

  // 手续费类型
  // 1 固定值：手续费
  // 2 百分比：买入->交易金额 * 手续费比例；卖出->交易金额 * 市价 * 手
  const commissionType = localCoin?.commissionType ?? 0;

  // 价格精度
  const priceAccuracyLength = localCoin?.priceAccuracy ?? 6;

  //手续费
  const commission = localCoin?.commission;

  // 是否100% 购买
  const [isBuyFull, setIsBuyFull] = useState(false);

  // 一个手续费
  const onePremium = useMemo(() => {
    // 固定手续费 -》 买入 和 卖出 都一样
    if (commissionType === 1) {
      return commission;
    }
    // 比例 买入计算 -》当前价 * 手续费比例
    if (commissionType === 2 && transactionMode === 1) {
      return commission * 0.01;
    }
    // 比例 卖出计算 -》交易金额 * 市价 * 1手
    if (commissionType === 2 && transactionMode === 2) {
      return 1 * socketData?.a * (commission * 0.01);
    }
  }, [
    commissionType,
    localCoin,
    commission,
    transactionMode,
    amount,
    socketData?.a,
  ]);

  // 交易精度，后台控制值，输入框
  const transactionAccuracy = localCoin?.transactionAccuracy ?? 6;
  const renderList = useMemo(() => {
    return coinsFunds?.walletList?.map((item: any) => {
      // 查找socket推送的币币列表
      const findItem = renderFunds?.coin?.walletList?.find?.(
        (fundsItem: any) => {
          return fundsItem?.name === item?.name;
        },
      );
      return {
        ...item,
        availableBalance: findItem?.ab ?? item?.availableBalance,
        convertUsd: findItem?.abu ?? item?.convertUsd,
        freezeBalance: findItem?.fb ?? item?.freezeBalance,
      };
    });
  }, [coinsFunds, renderFunds]);
  // 获取可用金额
  const availableAmount = useMemo(() => {
    // 买入 交换币 -》基币钱包
    // 卖出 基币钱包 -》 交换币钱包
    if (
      localCoin &&
      renderList?.length !== 0 &&
      transactionMode === 1
    ) {
      return renderList?.find(
        (item: any) => item.coinId === localCoin?.exchangeId,
      );
    }
    if (
      localCoin &&
      renderList?.length !== 0 &&
      transactionMode === 2
    ) {
      return renderList?.find(
        (item: any) => item.coinId === localCoin?.baseId,
      );
    }
  }, [localCoin, renderList, transactionMode]);

  // 最大手数 == 100% 的手数
  const maxLot = useMemo(() => {
    // 余额 减去手续费 除以  1手价钱 得到 100% 的手数
    if (transactionMode === 1) {
      // @ts-ignore
      return availableAmount?.availableBalance / (onePremium + 1);
    }
    return availableAmount?.availableBalance;
  }, [availableAmount?.availableBalance, transactionMode, onePremium]);

  //仓位列表
  const [currentPosition, setCurrentPosition] = useState(0);

  //处理拉杆
  function handleSliderChange(value: any) {
    // 确保 userAmount 和 soketData 已经正确加载
    if (!socketData?.a) {
      console.error('必要的数据未加载完毕');
      return;
    }
    setIsBuyFull(value === 100 ? true : false);

    // 最大手数 * 拉杆值 *0.01
    const maxNum = maxLot * (value * 0.01);
    // 设置最终计算的购买数量
    onAmountChange(RenderUtil.formatNum(maxNum, transactionAccuracy));
    setCurrentPosition(value);
  }

  // 输入数量设置拉杆位置
  const onAmountChangeSetCurrentPosition = (value: any) => {
    if (!availableAmount?.availableBalance || !socketData?.a) {
      return;
    }
    // 最大手数 * 拉杆值 *0.01
    // 购买 需要 / 行情
    let maxNum =  maxLot;

    if (maxNum < 0.001) {
      maxNum = 0.01;
    }
    let currentPosition = value / maxNum * 100;
    if (currentPosition > 100) {
      currentPosition = 100;
    }
    if (currentPosition < 0) {
      currentPosition = 0;
    }
    setCurrentPosition(currentPosition);
  };

  useEffect(() => {
    onAmountChange(0);
  }, []);

  useUpdateEffect(() => {
    // 市价变化到 限价 时候，直接赋值到当前价
    if (+priceType === TransactionTypeEnum.LIMIT && socketData?.a) {
      onPriceChange(Number(socketData?.a)?.toFixed?.(priceAccuracyLength));
    }
  }, [priceType]);

  //最小购买
  //币币最小购买
  const { appInfo } = useModel('appInfo');
  const { COIN_MINIMUM_EXCHANGE_AMOUNT = 0.001 } = appInfo || {};
  const minBuyNum = useMemo(() => {
    return COIN_MINIMUM_EXCHANGE_AMOUNT / (socketData.a || 0.01);
  }, [COIN_MINIMUM_EXCHANGE_AMOUNT, socketData]);
  // 选择限价时 更改币种 修改价格
  useEffect(()=>{
    setPrice(socketData?.a);
  },[localCoin])

  // useEffect(()=>{
  //   onAmountChange(0)
  //   setCurrentPosition(0)
  // },[localCoin])
  return (
    <CoinTradeContext.Provider value={modelProps}>
      <div className=" flex-1">
        {/* //买入卖出 */}

        <div key={orderKey + transactionMode + systemOrderKey + priceType}>
          {/*左侧表单*/}
          <>
            {/*限价格*/}

            <div className="justify-between text-xs pt-1 pb-3">
              <span className="text-auxiliaryTextColor">{t('可用')}</span>
              <span className="text-backContrastColor ml-2">
                {RenderUtil.FormatAmount(
                  availableAmount?.availableBalance,
                  priceAccuracyLength,
                )}
                <span className="ml-1">{localCoin?.baseSymbol}</span>
              </span>
            </div>

            {/*限价单表单*/}
            {+priceType === TransactionTypeEnum.LIMIT && (
              <>
                <BaseInputNumber
                  title={t('价格')}
                  value={price}
                  len={priceAccuracyLength}
                  step={0.01}
                  onChange={onPriceChange}
                />
              </>
            )}

            {/*市价单表单*/}
            {+priceType === TransactionTypeEnum.MARKET && (
              <>
                <div
                  className="text-xs bg-backgroundAuxiliaryColor/80  h-[40px] flex items-center justify-center text-center rounded-[6px] my-2 py-2 select-none">
                  {t('以当前最优价格交易')}
                </div>
              </>
            )}

            <BaseInputNumber
              title={t('数量')}
              className="mt-5"
              value={amount}
              showButton={false}
              len={transactionAccuracy}
              defaultValue={''}
              placeholder={t('最小数量{{num}} {{coinName}}', {
                num: RenderUtil.FormatAmount(minBuyNum, 6, false),
                coinName: localCoin?.baseSymbol,
              })}
              step={1}
              max={5}
              onChange={(value: any) => {
                onAmountChange(value);
              }}
              onRealTimeInput={(value: any) => {
                onAmountChangeSetCurrentPosition(Number(parser(value)));
              }}
            />

            <div className="pt-5"></div>

            <div className="pt-1 overflow-visible px-1">
              <BaseSlideRange
                values={[1, 25, 50, 75, 100]}
                step={1}
                value={currentPosition}
                isShowButtonNumber={true}
                onChange={handleSliderChange}
              />
            </div>

            <div className="pt-5"></div>

            <div className="justify-between text-xs">
              <span className="text-auxiliaryTextColor">{t('交易额')}</span>
              <span className="text-backContrastColor ml-2">
                {RenderUtil.FormatAmount(amount * socketData?.a, 2)}
                {ProjectSetting.APP_DEFAULT_CURRENCY}
              </span>
            </div>
          </>
        </div>

        {/*交易信息 购买按钮*/}
        <TransactionInformation
          priceType={priceType}
          transactionMode={transactionMode}
          isBuyFull={isBuyFull}
          onSuccess={() => {
            onAmountChange(0.01);
            walletFundReq();
          }}
        />
      </div>
    </CoinTradeContext.Provider>
  );
};

interface IProps {
  reloadOrder?: () => void;
  priceType?: any;
}
