import { SvgSpinnersBarsRotateFade } from '@/assets/icons/comm/SvgSpinnersBarsRotateFade';
import { AntDesignFilterFilled } from '@/assets/icons/funds/AntDesignFilterFilled';
import BasePullList from '@/components/base/basePullList/index';
import BaseTabs from '@/components/base/baseTabs';
import { FeaturesDateTime } from '@/components/features/featuresDateTime';
import CoinOrderCard from '@/pages/order/index/components/CoinOrderCard';
import { getCoinOrderPageList } from '@/services/api/requestApi';
import { useModel } from '@@/exports';
import { Button, Checkbox, cn, Divider, useDisclosure } from '@nextui-org/react';
import React, {
  forwardRef,
  ReactNode,
  useImperativeHandle,
  useMemo,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { BaseEmpty } from '@/components/base/baseEmpty';
import { useCoinModel } from '@/pages/transaction/coinModel';
import { OrderEmptyStatus } from '@/assets/icons/comm/OrderEmptyStatus';
import RenderUtil from '@/utils/RenderUtil';
import ProjectSetting from '@/setting/projectSetting';
import AddMoneyEntranceButtonModal from '@/pages/transaction/components/AddMoneyEntranceButtonModal';
import PageEnum from '@/enums/pageEnum';
import { ContractOrderStatusEnum } from '@/pages/order/index/components/ContractOrderList';
import { useLocalStorage } from '@/hooks/useStorage';
import CacheEnum from '@/enums/cacheEnum';

// 币币订单状态
export enum CoinOrderStatusEnum {
  // 当前委托
  ON_COMMISSION = 1,
  // 历史成交
  HISTORICAL_TRANSACTIONS = 0,
  // 已撤销
  REVOCATION = -1,
}

// 币币订单可接受参数
export interface CoinProps {
  symbol?: string;
  componentsType?: string;
  siftNode?: ReactNode;
  isShowLoadingDisplay?: boolean; // 是否在tab 后面进行加载显示
}

export default forwardRef(
  ({ symbol, siftNode, isShowLoadingDisplay = true }: CoinProps, ref) => {
    // 多币种持仓
    const [multiCurrencyPositions, setMultiCurrencyPositions] = useLocalStorage(
      {
        key: CacheEnum.APP_COIN_POSITION_LIST,
        defaultValue: false,
      },
    );
    const { localCoin }: any = useModel('coin');
    const { coinsFunds, renderFunds } = useModel('user');
    const renderList = useMemo(() => {
      return coinsFunds?.walletList?.map((item: any) => {
        // 查找socket推送的币币列表
        const findItem = renderFunds?.coin?.walletList?.find?.(
          (fundsItem: any) => {
            return fundsItem?.name === item?.name;
          },
        );
        return {
          ...item,
          availableBalance: findItem?.ab ?? item?.availableBalance,
          convertUsd: findItem?.abu ?? item?.convertUsd,
          freezeBalance: findItem?.fb ?? item?.freezeBalance,
        };
      });
    }, [coinsFunds, renderFunds]);

    // 基础定义
    const { t } = useTranslation();
    const [CoinOrderStatusEnumMap] = useState([
      {
        text: t('当前委托'),
        value: CoinOrderStatusEnum.ON_COMMISSION,
      },
      {
        text: t('历史成交'),
        value: CoinOrderStatusEnum.HISTORICAL_TRANSACTIONS,
      },
      {
        text: t('已撤销'),
        value: CoinOrderStatusEnum.REVOCATION,
      },
    ]);
    const [currentTab, setCurrentTab] = useState<CoinOrderStatusEnum>(
      CoinOrderStatusEnum.ON_COMMISSION,
    );
    const [time, setTime] = useState<string[]>([]);
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [pageParams, setPageParams] = useState({
      pageNo: 1,
      pageSize: 10,
    });
    // 刷新订单
    const [orderKey, setOrderKey] = useState(0);
    const refreshOrder = () => {
      setOrderKey((prevState) => prevState + 1);
    };

    const { orderKey: sysOrderKey } = useModel('system');

    // 请求参数(依赖更新,分页hooks会自动发起请求)
    const params = useMemo(() => {
      return {
        status: currentTab,
        startTime:
          time.length > 0 ? encodeURI(time[0] + 'T00:00:00') : undefined,
        endTime: time.length > 0 ? encodeURI(time[1] + 'T23:59:59') : undefined,
        coinId: multiCurrencyPositions ? undefined : symbol,
        createTime:
          time.length > 0
            ? [
              encodeURI(time[0] + 'T00:00:00'),
              encodeURI(time[1] + 'T23:59:59'),
            ]
            : undefined,
        ...pageParams,
      };
    }, [currentTab, time, pageParams, orderKey, symbol, sysOrderKey,multiCurrencyPositions]);
    // 用于做外部加载
    const [loadingDisplay, setLoadingDisplay] = useState(false);
    // 暴露方法
    useImperativeHandle(ref, () => ({
      loadingDisplay,
      refreshOrder,
    }));


    // 分页请求
    return (
      <div>
        <div className="flex justify-between items-center pr-4">
          <BaseTabs
            tabsPropsProps={{
              className: 'w-full',
              classNames: {
                tab: 'first:!pl-3',
                tabList: 'pb-0',
              },
            }}
            value={currentTab!}
            onChange={setCurrentTab}
            options={CoinOrderStatusEnumMap!}
          />
          <div>
            {/* 加载 */}
            {isShowLoadingDisplay && loadingDisplay && (
              <div
                className={cn(
                  'flex items-center justify-center w-fit h-full ',
                  { 'mr-1': siftNode },
                )}
              >
                <SvgSpinnersBarsRotateFade />
              </div>
            )}
            {siftNode ? (
              siftNode
            ) : (
              <AntDesignFilterFilled
                className="text-iconFontColor text-base cursor-pointer"
                onClick={onOpen}
              />
            )}
          </div>
        </div>
        {/*时间筛选组件*/}
        <FeaturesDateTime
          open={isOpen}
          onClose={onClose}
          onCancel={onClose}
          onConfirm={(value: any) => {
            const { start, end } = value;
            setTime?.([start, end]);
            onClose();
          }}
        />
        <Divider className="mb-4 !bg-backgroundAuxiliaryColor" />
        {/*列表*/}
        <div
          className={cn(`flex items-center  pl-4 pt-2 pb-3`, {
            'justify-end': location.pathname === PageEnum.ORDER,
            'justify-between': location.pathname !== PageEnum.ORDER,
          })}
        >
          {!symbol && <span></span>}
          {symbol && location.pathname !== PageEnum.ORDER && (
            <Checkbox
              size="sm"
              defaultSelected
              color="primary"
              isSelected={multiCurrencyPositions}
              classNames={{
                wrapper: cn(
                  `after:bg-[#f4f4f5] hover:!bg-[transparent] text-[#000] before:border-[#f4f4f5]`,
                ),
                base: 'hover:!bg-[transparent]',
              }}
              onValueChange={(value) => {
                setMultiCurrencyPositions(value);
              }}
            >
              <span className={cn(`mr-0.5 text-[12px]`)}>
                {t('显示所有仓位')}
              </span>
            </Checkbox>
          )}
        </div>

        <BasePullList
          fetchFunction={getCoinOrderPageList} // 请求数据函数
          params={params} // 请求参数
          setParams={setPageParams}
          loadingDisplay={false}
          setLoadingDisplay={setLoadingDisplay}
          renderItem={(item: any, index: number) => {
            return (
              <div className="px-4">
                <CoinOrderCard
                  key={item?.id}
                  item={item}
                  index={index}
                  refreshOrder={refreshOrder}
                />
              </div>
            );
          }} // 渲染列表项
        />
      </div>
    );
  },
);
