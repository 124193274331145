import BaseTabs from '@/components/base/baseTabs';
import { useModel } from '@umijs/max';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import PcContractLeft from './PcContractLeft';
import PcContractRight from './PcContractRight';

/**
 * 交易 表单
 */
export default function PcContract() {
  // 限价/市价
  const { reLoadOrder } = useModel('system');
  const [type, setType] = useState<any>('1');
  const { t } = useTranslation();

  const tabOptions = [
    { text: t('市价交易'), value: '1' },
    { text: t('限价交易'), value: '2' },
  ];
  return (
    <div className="pt-3 text-foreground px-2 h-full flex flex-col justify-between overflow-auto ">
      <div>
        <BaseTabs
          value={type}
          onChange={setType}
          options={tabOptions}
          classNames={{ active: ' text-pcPrimary' }}
        />

        <div className="flex justify-between items-center">
          {/* <div className="flex items-center pb-3 text-xs mt-3">
            <div className="">
              {t('可用')} {localContract?.coinAlias}
            </div>
            <div className=" font-bold ml-1">
              {RenderUtil.FormatAmount(
                contractAvailableFunds,
                2,
              )}
            </div>
          </div> */}

          {/*<BaseTabs*/}
          {/*  value={tabSelected}*/}
          {/*  onChange={setTabSelected}*/}
          {/*  options={[*/}
          {/*    { text: t('全仓'), value: '1' },*/}
          {/*    { text: t('逐仓'), value: '2' },*/}
          {/*  ]}*/}
          {/*  variant="bordered"*/}
          {/*/>*/}
        </div>

        <div className="flex justify-between space-x-2">
          <PcContractLeft type={type} isBuy={true} />
          <div className="border-r-1 border-borderColor border-dashed w-[1px]"></div>
          <PcContractRight reloadOrder={reLoadOrder} type={type} />
        </div>
      </div>
    </div>
  );
}
