import { BasilSearchSolid } from '@/assets/icons/comm/BasilSearchSolid';
import BaseInput from '@/components/base/baseInput';
import PullToRefresh from '@/components/base/basePullToRefresh';
import BaseTabs from '@/components/base/baseTabs';
import {
  ContractListTypeEnum,
  GetCoinListTypeEnum,
} from '@/enums/businessEnum';
import { usePaginationPage } from '@/hooks/usePaginationPage';
import { getTradingSymbolsApi } from '@/services/api/requestApi';
import useUrlState from '@ahooksjs/use-url-state';
import { useModel } from '@umijs/max';
import { useUpdateEffect } from 'ahooks';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import HomePcMarketList from './HomePcMarketList';

export default () => {
  const ref = useRef(null);
  const { t } = useTranslation();
  /**
   * 过滤字段
   */
  const [filterText, setFilterText] = useState('');
  const [urlState] = useUrlState<any>({});
  // 标记是合约交易还是BB交易
  const { type = GetCoinListTypeEnum.CONTRACT } = urlState || {};
  //币种列表配置
  const tabOptions = [
    { text: t('极速合约'), value: GetCoinListTypeEnum.FASTCONTRACT },
    { text: t('合约'), value: GetCoinListTypeEnum.CONTRACT },
    { text: t('现货'), value: GetCoinListTypeEnum.COIN },
    { text: t('自选'), value: GetCoinListTypeEnum.COLLECT },
  ];
  //动态生成渲染配置项，只显示当前交易类型和自选
  const renderOptions = useMemo(() => {
    return tabOptions.filter((item) => {
      return item.value === type || item.value === GetCoinListTypeEnum.COLLECT;
    });
  }, [type, tabOptions]);
  //合约列表配置
  const tabOptionsContractOption = [
    // {text: t('外汇'), value: ContractListTypeEnum.FOREIGN_EXCHANGE},
    { text: t('虚拟币'), value: ContractListTypeEnum.CRYPTOCURRENCY },
  ];
  //当前tab选中
  const [tabSelected, setTabSelected] = useState(type);
  const [tabSelectedContract, setTabSelectedContract] = useState(
    tabOptionsContractOption[0],
  );

  useUpdateEffect(() => {
    setTabSelected(type);
  }, [type]);
  //列表传参
  const [params, setParams] = useState<any>({
    pageNo: 1,
    pageSize: 20,
    type: tabSelected,
    contractType: tabSelectedContract?.value,
    isTripartite: type, // 筛选出自选(收藏)是哪种交易，目前就是合约和币币（默认合约）
  });

  const { sortState } = useModel('system');

  useUpdateEffect(() => {
    // 搜索框输入时，延迟500ms再请求
    setParams({
      ...params,
      ...sortState,
      fuzzy: filterText,
      pageNo: 1,
      type: tabSelected,
      contractType: tabSelectedContract?.value,
      isTripartite: type, // 筛选出自选(收藏)是哪种交易，目前就是合约和币币（默认合约）
    });
  }, [sortState]);

  //重新获取列表
  useUpdateEffect(() => {
    // 搜索框输入时，延迟500ms再请求
    setParams({
      ...params,

      fuzzy: filterText,
      pageNo: 1,
      type: tabSelected,
      contractType: tabSelectedContract?.value,
      isTripartite: type, // 筛选出自选(收藏)是哪种交易，目前就是合约和币币（默认合约）
    });
  }, [tabSelected, tabSelectedContract, filterText]);

  //列表
  const { list, loading, loadMore, error, total, ...resetPageProps } =
    usePaginationPage({
      fn: getTradingSymbolsApi,
      params,
      setParams,
    });

  const filterList: any = useMemo(() => {
    if (
      filterText &&
      list?.length &&
      tabSelected === GetCoinListTypeEnum.COLLECT
    ) {
      return list.filter(
        (i: any) =>
          i?.alias
            ?.toLocaleUpperCase()
            .includes(filterText.toLocaleUpperCase()) ||
          i?.name?.toLocaleUpperCase().includes(filterText.toLocaleUpperCase()),
      );
    }

    return list;
  }, [filterText, tabSelected, list, type]);

  const [key, setKey] = useState(1);
  useEffect(() => {
    setKey((prevState) => prevState + 1);
  }, [list]);

  return (
    <div className=" relative" ref={ref}>
      <BaseInput
        value={filterText}
        className="bg-backgroundAuxiliaryColor  overflow-hidden h-[40px]"
        startContent={
          <BasilSearchSolid className=" text-iconFontColor text-xl" />
        }
        placeholder={t('搜索')}
        classNames={{
          base: 'rounded-md overflow-hidden h-[40px]',
          inputWrapper:
            'h-full  rounded-md  group-data-[focus=true]:border-background !border-background',
        }}
        size="sm"
        onChange={(e) => {
          setFilterText(e.target.value);
        }}
      />
      <div className=" h-full   max-h-[50vh] pr-2">
        <div className="flex justify-between items-center mb-4">
          <BaseTabs
            value={tabSelected}
            onChange={setTabSelected}
            options={renderOptions}
          />
        </div>

        <div key={tabSelected + key}>
          <PullToRefresh
            loadMore={loadMore}
            total={total}
            list={filterList}
            error={error}
            loading={loading}
            {...resetPageProps}
          >
            <HomePcMarketList
              isJump={false}
              type={tabSelected}
              selected
              list={filterList}
            />
          </PullToRefresh>
        </div>
      </div>
    </div>
  );
};
