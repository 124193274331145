import * as echarts from 'echarts';
import { useEffect, useMemo, useRef } from 'react';

type IProps = {
  isUp: boolean;
  data: Array<number>;
  canvasWidth: string;
  canvasHeight: string;
  LineColor?: string;
  showBackFill?: boolean;
};

/**
 * 跟单使用的小波浪线
 */
export default (props: IProps) => {
  const { data, canvasWidth, canvasHeight, LineColor = 'grey' } = props;
  // 生成长度为n，值为1-n的数组
  const renderArray = (n: number) => {
    return Array.from(Array(n), function (value, index) {
      return index + 1;
    });
  };
  const chartLength = useMemo(() => {
    return renderArray(data?.length);
  }, [data]);
  const trend = useRef<any>();
  // 找到数据集中的最小值和最大值
  const minValue = Math.min(...data);
  const maxValue = Math.max(...data);
  useEffect(() => {
    const chart = echarts.init(trend.current);

    const options = {
      grid: {
        bottom: '10%',
        left: '0%',
        right: '0%',
        top: '10%',
        containLabel: true,
      },
      xAxis: {
        type: 'category',
        data: chartLength,
        axisLabel: {
          show: false,
        },
        axisTick: {
          show: false,
        },
        axisLine: {
          show: false,
        },
      },
      yAxis: {
        type: 'value',
        axisLabel: {
          show: false,
        },
        splitLine: {
          //设置y坐标上分割线的颜色
          lineStyle: {
            color: LineColor,
          },
        },
        min: minValue, // 设置最小值为数据集中的最小值
        max: maxValue, // 设置最大值为数据集中的最大值
      },
      series: [
        {
          data: props?.data,
          type: 'line',
          animation: false, //去掉动画效果
          smooth: true,
          symbol: 'circle', // 拐点的形状
          symbolSize: 0, // 拐点大小
          areaStyle: {
            color: {
              type: 'linear',
              x: 0,
              y: 0,
              x2: 0,
              y2: 1,
              colorStops: [
                // 渐变颜色
                {
                  offset: 0,
                  color: props.isUp
                    ? 'rgba(71,171,134,0.5)'
                    : 'rgba(221,93,81,0.5)',
                },
                {
                  offset: 1,
                  color: props.isUp
                    ? 'rgba(71,171,134,0)'
                    : 'rgba(221,93,81,0)',
                },
              ],
              global: false,
            },
          },
          lineStyle: {
            color: props.isUp ? '#47ab86' : '#dd5d51',
            width: 1.7,
          },
        },
      ],
    };
    const handleResize = () => {
      chart.resize();
    };

    chart.resize({ width: 'auto', height: 'auto' });
    chart.setOption(options);
    window.addEventListener('resize', handleResize);
    return () => {
      chart.dispose();
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  return (
    <div
      id="trend"
      ref={trend}
      style={{ width: canvasWidth + '', height: canvasHeight + '' }}
    />
  );
};
