import BaseWithErrorBoundary from '@/components/base/baseWithErrorBoundary';
import ValueDisplay from '@/components/display/displayValueDisplay';
import FeaturesCollect from '@/components/features/featuresCollect';
import { CollectEnum, GetCoinListTypeEnum } from '@/enums/businessEnum';
import { getChartPageInfoApi } from '@/services/api/requestApi';
import { useReq } from '@/services/net/request';
import RenderUtil from '@/utils/RenderUtil';
import useUrlState from '@ahooksjs/use-url-state';
import { useModel } from '@umijs/max';
import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

/**
 * # 首页图表头部展示
 */
export default BaseWithErrorBoundary(() => {
  const { t } = useTranslation();
  const { getSocketRowByName } = useModel('socket');
  const { localContract, localFastContract } = useModel('contract');
  const { localCoin } = useModel('coin');
  // 标记是合约交易还是BB交易
  const [urlState] = useUrlState<any>({});
  const { type = GetCoinListTypeEnum.CONTRACT } = urlState || {};
  // 渲染本地符号数据
  const renderLocalChart = useMemo(() => {
    // 渲染本地符号数据

    if (type === GetCoinListTypeEnum.FASTCONTRACT) {
      return localFastContract;
    }
    if (type === GetCoinListTypeEnum.CONTRACT) {
      return localContract;
    } else if (type === GetCoinListTypeEnum.COIN) {
      return localCoin;
    }
  }, [type, localContract, localCoin, localFastContract]);

  // 渲染本地符号数据

  const socketRowByName = getSocketRowByName(renderLocalChart?.symbol);
  const { data = {} as any, runAsync } = useReq(
    () =>
      getChartPageInfoApi({
        symbol: renderLocalChart?.symbol,
        noMsg: true,
      }),
    {},
  );

  useEffect(() => {
    runAsync();
  }, [renderLocalChart]);

  const priceAccuracy = renderLocalChart?.priceAccuracy;

  const collectType: CollectEnum = useMemo(() => {
    if (type === GetCoinListTypeEnum.FASTCONTRACT) {
      return CollectEnum.FAST_CONTRACT_COLLECT;
    }
    if (type === GetCoinListTypeEnum.CONTRACT) {
      return CollectEnum.CONTRACT_COLLECT;
    } else if (type === GetCoinListTypeEnum.COIN) {
      return CollectEnum.COIN_COLLECT;
    }
    return CollectEnum.COIN_COLLECT;
  }, [type, localContract, localCoin, localFastContract]);

  return (
    <div className="flex items-center justify-between gap-5 p-2">
      <div className="flex-1 flex items-center gap-4">
        <span className="">
          {/* //收藏按钮 */}
          <FeaturesCollect collectType={collectType} />
        </span>

        {/* title */}
        <div className="flex">
          <div className=" flex gap-2 items-center">
            <div className=" text-titleColor text-base font-bold whitespace-nowrap">
              {renderLocalChart?.alias}
            </div>
          </div>
          <div className="py-1 text-base  ml-2 flex justify-between items-center gap-1 rounded-md whitespace-nowrap">
            <ValueDisplay
              value={socketRowByName?.a}
              len={renderLocalChart?.priceAccuracy}
              isSubLen={false}
              style={{ fontSize: 16 }}
              className="!font-[500]"
              justify="justify-start"
            />
          </div>
        </div>
        {/* 统计 */}
        <div className="flex flex-1 justify-end items-center gap-10 w-full text-xs">
          <div className=" flex  flex-col items-center justify-center ">
            <span className=" text-iconFontColor whitespace-nowrap mr-2.5">
              {t('涨幅')}
            </span>
            <ValueDisplay
              value={socketRowByName?.pe}
              ratio
              style={{ fontSize: 12 }}
              justify="justify-start"
            />
          </div>

          <div className=" flex  flex-col items-center justify-center ">
            <span className="text-iconFontColor whitespace-nowrap mr-2.5">
              24h {t('最高价')}
            </span>

            {RenderUtil.FormatAmount(
              socketRowByName?.a > data?.high ? socketRowByName?.a : data?.high,
              priceAccuracy,
              true,
            )}
          </div>
          <div className=" flex  flex-col items-center justify-center ">
            <span className="text-iconFontColor whitespace-nowrap mr-2.5">
              24h {t('最低价')}
            </span>
            {RenderUtil.FormatAmount(
              socketRowByName?.a < data?.low ? socketRowByName?.a : data?.low,
              priceAccuracy,
              true,
            )}
          </div>

          <div className=" flex  flex-col items-center justify-center ">
            <span className=" text-iconFontColor whitespace-nowrap mr-2.5">
              {t('24H量')}
            </span>
            {RenderUtil.shortenNumber(RenderUtil.formatNum(data?.volume))}
          </div>
        </div>
      </div>
    </div>
  );
});
