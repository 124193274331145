import BaseSwiper from '@/components/base/baseSwiper';
import { getCarouselImageApi } from '@/services/api/requestApi';
import { useReq } from '@/services/net/request';
import { useMount } from 'ahooks';
//首页 轮播

export default () => {
  // 使用自定义钩子获取轮播图数据
  const {
    data: carousel = [] as any,
    run: getCarouselImage,
    loading: loadingCarousel,
  } = useReq(getCarouselImageApi, {
    manual: true,
    defaultValue: [],
    formatResult(res: any) {
      return res.data ?? [];
    },
  });

  useMount(() => {
    getCarouselImage({ purpose: 2 });
  });

  return (
    <div>
      {/* 广告部分 */}
      <div className="w-full px-4 mt-4 ">
        <BaseSwiper
          height={150}
          imgArr={carousel?.map((item: any) => item.imgUrl)}
          loading={loadingCarousel}
          className="rounded-[8px]"
        />
      </div>
    </div>
  );
};
