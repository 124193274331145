import BaseModal, {IProps} from "@/components/base/baseModal";
import {useTranslation} from "react-i18next";

export default (props: IProps & {  confirmModalClassName?: any }) => {
    const {t} = useTranslation();
    const {confirmModalClassName = {content: ''}} = props
    return <div>
        <BaseModal classNames={{title: 'text-[18px] pt-[8px] text-[#161616] font-semibold'}}
                   placement='center'
                   {...props}
        >

            <div className='px-[24px] pb-[24px]'>
                <div
                    className={`text-auxiliaryTextColor text-[16px] text-center w-[263px] ${confirmModalClassName?.content}`}>
                    {props.children}
                </div>
                <div className='flex justify-center mt-[24px] gap-[12px]'>

                    <div
                        onClick={props?.onClose}
                        className='flex justify-center items-center rounded-[8px] text-[16px] bg-primary text-white w-full h-[40px]'>{t('确认')}</div>
                </div>
            </div>

        </BaseModal>
    </div>
}