import BaseWithErrorBoundary from '@/components/base/baseWithErrorBoundary';
import FastContractTradeForm from '@/pages/contract/components/FastContractTradeForm';
import { createQuickOrderUrlApi } from '@/services/api/requestApi';
import { useReq } from '@/services/net/request';
import { useModel } from '@umijs/max';
import { useTranslation } from 'react-i18next';
import { toast } from '@/layouts/components/ToastMessage';

/**
 * 股票交易 表单
 */
export default BaseWithErrorBoundary(() => {
  const { t } = useTranslation();
  const { refreshWallet } = useModel('user');
  const { reLoadOrder } = useModel('system');

  //购买一个订单
  const { run: onSubmitRequest, loading: submitLoading } = useReq(
    createQuickOrderUrlApi,
    {
      loadingDefault: false,
      manual: true,
      onSuccess: () => {
        refreshWallet();
        toast.success(t('操作成功'));
          reLoadOrder();
      },
    },
  );

  return (
    <>
      <FastContractTradeForm
        contentMaxHeight={9000}
        onSubmitRequest={onSubmitRequest}
        submitLoading={submitLoading}
      />
    </>
  );
});

